import styles from './index.module.scss'
import {useRef, useState} from 'react'

const Input = ({type, placeholder,id}) => {
    const [value, setValue] = useState(false)
    const inputValue = useRef(null)
    const placeholderControl = () => {
        const inputText = inputValue.current.value
        const condition = inputText == '' && value
        if (condition) {
            setValue(false)
            return;
        }
        if (!condition) {
            setValue(true)
            return
        }
    }
    return (
        <div className={styles['input-div']}>
            <input id={id} onInput={() => {
                placeholderControl()
            }} ref={inputValue} type={type}/>
            <span className={`${value ? styles['input-value'] : styles['input-empty']}`}>{placeholder}</span>
        </div>
    )
}
export default Input;