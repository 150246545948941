import styles from './index.module.scss'
import text from './text.json'
import PageTitle from "../../components/core/pageTitle"
import InnerPageHeader from "../../components/pages/innerPageHeader"
import {TrainingCard} from '../../components/core/cards'
import {useEffect, useState} from 'react'
import Service from '../../api/training'
import Loading from '../../components/pages/loading'
import { MainContext, useContext } from '../../context'

const Training = () => {
   const [load,SetLoad] = useState(false)
   const [data,setData] = useState([])
   const {selectedLang} = useContext(MainContext)
   useEffect(async () => {
      const query =`?lang=${selectedLang}`
      const {data} = await Service.getData(query)
      setData(data)
      SetLoad(true)
   },[selectedLang])
   return (
      load?
      <section className={styles.container}>
         <InnerPageHeader/>
         <PageTitle>{text[selectedLang].title}</PageTitle>

         <div className={styles.content}>
            {data.map(item=>(
                 <TrainingCard key={item.id} img={item.cover_image} link={`/training/${item.id}`} title={item.title} text={item.place} />
            ))}
         </div>
      </section> : <Loading/>
   )
}

export default Training