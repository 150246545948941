import Hero from "./hero";
import Slides from "./slides";
import Info from "./info";
import Appeal from "./appeal";
import AchievementsItem from "../../components/pages/achievementsItem";
import News from "./news";
import Athletes from "./athletes";
import Events from "./events";
import School from "./school";
import Video from "./video";
import Iframe from "./iframe";
import styles from "./index.module.scss";
import { useState, useEffect } from "react";
import { MainContext, useContext } from "../../context";
import Loading from "../../components/pages/loading";
import HomeService from "../../api/home";
import AchievementService from "../../api/achievements";
import NewsService from "../../api/news";
import MeasuresService from "../../api/measures";
import SchoolService from "../../api/school";
import ContactService from "../../api/contact";
import AppealsService from "../../api/appeals";

const Home = () => {
  const [video, setVideo] = useState();
  const [bool, setBool] = useState(false);
  const [bool2, setBool2] = useState(false);
  const [bool3, setBool3] = useState(false);
  const [load, SetLoad] = useState(true);
  const [heroData, setHeroData] = useState([]);
  const [slidesData, setSlidesData] = useState([]);
  const [dataData, setdataData] = useState([]);
  const [achievementsData, setAchievementsData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [athletesData, setAthletesData] = useState([]);
  const [measuresData, setMeasuresData] = useState([]);
  const [dataById, setDataById] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [appealsData, setAppealsData] = useState([]);
  const [dataById1, setDataById1] = useState([]);

  const { selectedLang } = useContext(MainContext);

  document.querySelectorAll(".dark").forEach((item) => {
    item.style.filter = bool ? "brightness(60%)" : null;
  });
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await HomeService.getVideo();
    await setVideo(data.cover_video);
    await setBool2(data.cover_video);
    await setBool(data.cover_video);
    const [
      heroData1,
      slidesData1,
      dataData1,
      achievementsData1,
      newsData1,
      athletesData1,
      measuresData1,
      dataById1,
      contactData1,
      appealsData1,
      dataById21,
    ] = await Promise.all([
      HomeService.getHeroSliders(),
      HomeService.getDownSlider(query),
      HomeService.getData(query),
      AchievementService.getAchievements(query),
      NewsService.getHomeNews(query),
      HomeService.getNineAthletes(),
      MeasuresService.getSixMeasures(query),
      SchoolService.getDataById(1, query),
      ContactService.getContactInfo(query),
      AppealsService.getAppealsTypes(query),
      SchoolService.getDataById(1, query),
    ]);

    setHeroData(heroData1?.data);
    setSlidesData(slidesData1?.data);
    setdataData(dataData1?.data);
    setAchievementsData(achievementsData1?.data);
    setNewsData(newsData1?.data);
    setAthletesData(athletesData1?.data);
    setMeasuresData(measuresData1?.data);
    setDataById(dataById1?.data);
    setContactData(contactData1?.data);
    setAppealsData(appealsData1?.data);
    setDataById1(dataById21?.data);
    SetLoad(false);
  }, [selectedLang]);
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  return !load ? (
    <div className={styles.parent}>
      <main className={`dark ${styles.home}`}>
        <Hero data={heroData} />
        <Slides data={slidesData} />
        <Info data={dataData} />
        <AchievementsItem data={achievementsData} />
        <News data={newsData} />
        <Athletes data={athletesData} />
        <Events data={measuresData} />
        <School
          open={() => {
            setBool(true);
            setBool3(true);
          }}
          data_={dataById}
        />
        <Appeal dataLeft={contactData} dataRight={appealsData} />
      </main>
      {bool2 ? (
        <Video
          click={() => {
            setBool(false);
            setBool2(false);
          }}
          src={linkBuilder(video)}
        />
      ) : null}
      {bool3 ? (
        <Iframe
          close={() => {
            setBool(false);
            setBool3(false);
          }}
          data={dataById1}
        />
      ) : null}
    </div>
  ) : (
    <Loading />
  );
};

export default Home;
