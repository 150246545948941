import styles from "./index.module.scss";
import text from "./text.json";
import InnerPageHeader from "../../components/pages/innerPageHeader";
import PageTitle from "../../components/core/pageTitle";
import { NewsCard } from "../../components/core/cards";
import ContactItem from "../../components/pages/contactItem";
import { useEffect, useState } from "react";
import NewsService from "../../api/news";
import Loading from "../../components/pages/loading";
import { MainContext, useContext } from "../../context";

const News = () => {
  const [load, SetLoad] = useState(false);
  const [news, setNews] = useState([]);
  const { selectedLang } = useContext(MainContext);
  const generateNews = () => {
    return news?.map((item) => <NewsCard key={item.id} news={item} />);
  };
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await NewsService.getAllNews(query);
    setNews(data);
    SetLoad(true);
  }, [selectedLang]);
  return load ? (
    <section className={styles.news}>
      <InnerPageHeader />
      <div className="container">
        <PageTitle>{text[selectedLang].news}</PageTitle>
        <div className={styles.content}>{generateNews()}</div>
      </div>
      <ContactItem />
    </section>
  ) : (
    <Loading />
  );
};

export default News;
