import axios from "../index";

const getHeroSliders = () => {
  return axios.get("up-slider");
};

const getNineAthletes = () => {
  return axios.get("nine-athletes");
};

const getData = (query) => {
  return axios.get(`get-data${query}`);
};

const getSportData = (id, query) => {
  return axios.get(`get-data/${id}${query}`);
};

const getDownSlider = (query) => {
  return axios.get(`down-slider${query}`);
};

const getSlideById = (id, query) => {
  return axios.get(`down-slider/${id}${query}`);
};

const getVideo = () => {
  return axios.get(`cover-video`);
};

export default {
  getHeroSliders,
  getNineAthletes,
  getData,
  getDownSlider,
  getSportData,
  getSlideById,
  getVideo,
};
