import styles from "./index.module.scss";
import data from "./data.json";
import LogoBlue from "../../../assets/images/logo-blue.svg";
import LogoBlueEng from "../../../assets/images/logo-blue-eng.svg";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import { FbIcon, InstaIcon, TwitterIcon, TiktokIcon } from "../../../icons";
import SocialIcon from "../../core/socialIcon";
import Column from "./column";
import { useEffect, useState } from "react";
import ContactService from "../../../api/contact";
import { MainContext, useContext } from "../../../context";

const Footer = ({ footerBg }) => {
  const [sosialInfo, setSosialInfo] = useState([]);
  const { selectedLang } = useContext(MainContext);
  const infoItem = (id, icon, href) => {
    return {
      id,
      icon,
      href,
    };
  };
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await ContactService.getContactInfo(query);
    setSosialInfo([
      infoItem(1, FbIcon, data?.facebook_link),
      infoItem(2, InstaIcon, data?.instagram_link),
      infoItem(3, TwitterIcon, data?.twitter_link),
      infoItem(4, TiktokIcon, data?.linkedin_link),
    ]);
  }, []);
  return (
    <footer
      className={`dark dark2 ${styles["footer-container"]} ${
        footerBg ? styles["bg-white"] : styles["bg-grey"]
      }`}
    >
      <div>
        <div>
          <Link to={routes.home.path}>
            <img
              src={selectedLang == "en" ? LogoBlueEng : LogoBlue}
              alt={data[selectedLang].avar_cekme}
            />
          </Link>
          <div>
            {sosialInfo.map((item) => (
              <SocialIcon key={item.id} href={item.href}>
                {<item.icon />}
              </SocialIcon>
            ))}
          </div>
        </div>
        <div>
          {data[selectedLang].columns.map((item) => (
            <Column
              key={`col-${item.id}`}
              title={item.title}
              links={item.links}
            />
          ))}
        </div>
      </div>
      <div>{data[selectedLang].footer_bottom_text}</div>
    </footer>
  );
};

export default Footer;
