import styles from "./index.module.scss";
import data from "./data.json";
import InnerPageHeader from "../../components/pages/innerPageHeader";
import PageTitle from "../../components/core/pageTitle";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FolderCol from "./folderCol";
import VideoCol from "./videoCol";
import "./style.css";
import GalleryService from "../../api/gallery";
import { useEffect, useState } from "react";
import Loading from "../../components/pages/loading";
import { MainContext, useContext } from "../../context";

const Structure = () => {
  const [load, SetLoad] = useState(false);
  const [Backdata, setBackdata] = useState([]);
  const { selectedLang } = useContext(MainContext);

  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await GalleryService.getData(query);
    const arr = [
      {
        data: data.sekiller,
      },
      {
        videos: data.videolar,
      },
    ];
    let videos = arr[1].videos;
    let panel2 = [];
    for (let i = 0; i < videos.length; i += 2) {
      panel2.push(videos.slice(i, i + 2));
    }
    arr[1].videos = [...panel2];
    setBackdata(arr);
    SetLoad(true);
  }, [selectedLang]);

  return load ? (
    <section className={styles.gallery}>
      <InnerPageHeader />
      <div>
        <PageTitle>{data[selectedLang].title}</PageTitle>
        <div className={styles.container}>
          <Tabs className="gallery-table">
            <TabList>
              {data[selectedLang].tabs.map((tab, index) => (
                <Tab key={index}>{tab}</Tab>
              ))}
            </TabList>
            {Backdata.map((item, i) => (
              <TabPanel key={i}>
                {item.data?.map((folders, i) =>
                  folders.images.length > 0 ? (
                    <FolderCol folders={folders} key={i} />
                  ) : null
                )}
                {item.videos?.map((videos, i) => (
                  <VideoCol videos={videos} key={i} />
                ))}
              </TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    </section>
  ) : (
    <Loading />
  );
};

export default Structure;
