import {NavLink} from "react-router-dom"
import styles from './index.module.scss'
import {MenuDown,LiveStream} from '../../../icons'
import ContactItem from '../../pages/contactItem'
import { MainContext, useContext } from "../../../context"
import { useState } from "react"
import { LangArrows } from "../../../icons"

const Sidebar = ({data}) => {
    const {selectedLang, setSelectedLang} = useContext(MainContext)
    const [isVisible,setIsVisible] = useState(false)

    const generateMenu = () => {
      return (
          <ul className={styles.menu}>
              {data[selectedLang].links.map(item => (
                  <li key={item.id}>
                      <NavLink to={item.path}>{item.name} {item.dropdown? <span><MenuDown/></span>:null}</NavLink>
                      {item.dropdown?
                      <ul className={styles.dropdown}>
                        {
                            item.dropdown.map((item2,i)=>(
                                <li>
                                    <NavLink to={item2.path}>{item2.name} {item2.submenu? <span><MenuDown/></span>:null}</NavLink>
                                    {item2.submenu?
                                    <ul className={styles.submenu}>
                                      {item2.submenu.map((item3,i)=>(
                                          <li><NavLink to={item3.path}>{item3.name}</NavLink></li>
                                      ))}
                                    </ul>:null}
                                </li>
                            ))
                        }
                      </ul>:null}
                  </li>
              ))}
          </ul>
      )
    }

    const handleLang = (lang) => {
        localStorage.setItem('lang', lang)
        setSelectedLang(lang)
        localStorage.setItem('lang', lang)
        setIsVisible(false)
    }

   return (
      <div className={styles.sidebar}>
         {generateMenu()}
         <div className={styles.live}>
            <div><LiveStream /> Online izlə</div>
            <div onBlur={()=>setTimeout(function() {setIsVisible(false)}, 200)}>
                <span onClick={()=>setIsVisible(true)} tabindex="1">{selectedLang.toUpperCase()}<LangArrows/></span>
                <ul style={isVisible ? {display:"flex"}:{display:"none"}}>
                    {data[selectedLang].languages.map((item,i)=>(
                        <li onClick={() =>{handleLang(item.lang)}} key={i}>{item.lang.toLocaleUpperCase()}</li>
                    ))}
                </ul>
            </div>
         </div>
            <ContactItem title={false} button={false} />
      </div>
   )
}
export default Sidebar