import styles from "./index.module.scss";
import FolderPng from "../../assets/images/system-blue-folder-icon.png";
import { Link } from "react-router-dom";
import { routes } from "../../routes";

const FolderCol = ({ folders }) => {
  return (
    <Link
      to={`${routes.gallery.path}/${folders?.id}`}
      className={styles["image-col"]}
    >
      <div>
        <img src={FolderPng} alt="" />
        <div style={{ textAlign: "center" }}>{folders.title}</div>
      </div>
    </Link>
  );
};
export default FolderCol;
