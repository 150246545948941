import styles from "./index.module.scss";
import { NewsFB, NewsWP, NewsTG, NewsLI, NewsTwitter } from "../../../icons";

const Footer = ({ date, text, shareTitle }) => {
  const location = window.location.href;
  const iconsItem = (id, icon, href) => {
    return {
      id,
      icon,
      href,
    };
  };
  const icons = [
    iconsItem(
      1,
      NewsFB,
      `https://www.facebook.com/sharer/sharer.php?u=${location}`
    ),
    iconsItem(2, NewsWP, `whatsapp://send?text=${location}`),
    iconsItem(
      3,
      NewsTG,
      `https://t.me/share/url?url=${location}&text=${shareTitle}`
    ),
    iconsItem(
      4,
      NewsLI,
      `https://www.linkedin.com/sharing/share-offsite/?url=${location}`
    ),
    iconsItem(
      5,
      NewsTwitter,
      `https://twitter.com/share?text=${shareTitle}&url=${location}`
    ),
  ];
  return (
    <div className={`dark ${styles.footer}`}>
      <div>
        <p>{text}</p>

        <ul>
          {icons.map((item) => (
            <li key={`footer-icons-${item.id}`}>
              <a href={item.href} target="_blank">
                <item.icon />
              </a>
            </li>
          ))}
        </ul>
      </div>
      {date ? <p>{date}</p> : null}
    </div>
  );
};

export default Footer;
