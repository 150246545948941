import styles from "./index.module.scss";
import data from "./data.json";
import staticWords from "./../../../translation.json";
import InfoIcon from "../../../assets/images/info-icon.svg";
import { LinkButton } from "../../../components/core/button";
import { RightArrowIcon } from "../../../icons";
import Title from "../../../components/core/title";
import { useState, useEffect } from "react";
import Helpers from "../../../utils/helpers";
import { MainContext, useContext } from "./../../../context";

const Info = ({ data }) => {
  const [active, setActive] = useState(true);
  const [enter, setEnter] = useState(false);
  const [activeInfoIndex, setActiveInfoIndex] = useState(1);
  const [info, setInfo] = useState([]);
  const links = ["/achievements", "/contact", "/history-az"];
  const { selectedLang } = useContext(MainContext);
  const setImageStatus = () => {
    setActive(!active);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      let cur = enter ? activeInfoIndex : activeInfoIndex + 1;
      if (cur < 4) {
        setActiveInfoIndex(cur);
      } else {
        setActiveInfoIndex(1);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [activeInfoIndex, enter]);
  useEffect(async () => {
    setInfo(data);
  }, [data]);

  return (
    <section className={`dark2 ${styles.info}`}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.left}>
            <div
              className={`${styles.icons} ${active && styles.active}`}
              onMouseEnter={setImageStatus}
              onMouseLeave={setImageStatus}
            >
              <img src={InfoIcon} alt="Avarçəkmə" />
              {info?.map((item) => (
                <div
                  key={item?.id}
                  className={activeInfoIndex === item?.id ? styles.hover : null}
                  onMouseLeave={() => {
                    setEnter(false);
                  }}
                  onMouseEnter={() => {
                    setEnter(true);
                    setActiveInfoIndex(item?.id);
                  }}
                >
                  <span>{item?.title}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.right}>
            {info?.map((item) => (
              <div
                className={`${styles["info-header"]} ${
                  item?.id === activeInfoIndex && styles.active
                }`}
              >
                <Title>
                  <h1>{item?.title}</h1>
                </Title>
                <p className={styles.description}>
                  {Helpers.textLengthChecker(item?.description, 250)}
                </p>
              </div>
            ))}
            <LinkButton
              to={links[activeInfoIndex - 1]}
              rightTrapezoid={true}
              className={styles["info-button"]}
            >
              <span>
                {activeInfoIndex === 2
                  ? staticWords[selectedLang].join_us
                  : staticWords[selectedLang].read_more}
                <RightArrowIcon />
              </span>
            </LinkButton>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
