import data_ from "./data.json";
import Title from "../../../components/core/title";
import ContactIcon from "../../../components/core/contactIcon";
import SosialIcon from "../../../components/core/socialIcon";
import { LinkButton2 } from "../../../components/core/button";
import {
  PhonesIcon,
  AddressIcon,
  MailIcon,
  FbIcon,
  InstaIcon,
  TwitterIcon,
  RightArrowIcon,
} from "../../../icons";
import { useEffect, useState } from "react";
import { MainContext, useContext } from "../../../context";

const FormLeft = ({ data }) => {
  const [contactinfo, setContactinfo] = useState([]);
  const [sosialinfo, setSosialinfo] = useState([]);
  const { selectedLang } = useContext(MainContext);

  useEffect(async () => {
    const infoItem = (id, icon, title) => {
      return {
        id,
        icon,
        title,
      };
    };
    setContactinfo([
      infoItem(1, PhonesIcon, data?.mobile_number),
      infoItem(2, AddressIcon, data?.address),
      infoItem(3, MailIcon, data?.email),
    ]);
    setSosialinfo([
      infoItem(1, FbIcon, data?.facebook_link),
      infoItem(2, InstaIcon, data?.instagram_link),
      infoItem(3, TwitterIcon, data?.twitter_link),
    ]);
  }, [data]);
  return (
    <div>
      <Title>
        <h1>{data_[selectedLang].appeal_left_title_up}</h1>
      </Title>

      <div>
        {contactinfo.map((item) => (
          <div key={item.id}>
            <ContactIcon key={item.id}>{<item.icon />}</ContactIcon>
            <p>{item.title}</p>
          </div>
        ))}
      </div>

      <div>
        <p>{data_[selectedLang].appeal_left_title_bottom}</p>
        <div>
          {sosialinfo.map((item) => (
            <SosialIcon key={item.id} href={item.title}>
              {<item.icon />}
            </SosialIcon>
          ))}
        </div>
      </div>
      <LinkButton2 to={"/contact"}>
        <span>
          {data_[selectedLang].appeal_right_form_button}
          <RightArrowIcon />
        </span>
      </LinkButton2>
    </div>
  );
};

export default FormLeft;
