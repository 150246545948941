import styles from "./index.module.scss";
import data_ from "./data.json";
import Title from "../../../components/core/title";
import { RightArrowIcon } from "../../../icons";
import { Link } from "react-router-dom";
import { routes } from "../../../routes";
import { NewsCard } from "../../../components/core/cards";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { MainContext, useContext } from "../../../context";
import "swiper/css";
import "swiper/css/pagination";

import "./styles.css";

const News = ({ data }) => {
  const [boolean, setBoolean] = useState(false);
  const [gap, setGap] = useState(0);
  const [news, setNews] = useState([]);
  const { selectedLang } = useContext(MainContext);
  const generateNews = () => {
    return news?.map((item) =>
      boolean ? (
        <SwiperSlide>
          <NewsCard key={item.id} news={item} />
        </SwiperSlide>
      ) : (
        <NewsCard key={item.id} news={item} />
      )
    );
  };
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) {
        setBoolean(true);
        setGap(10);
        return;
      }
      setBoolean(false);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
  }, []);
  useEffect(async () => {
    setNews(data);
  }, [data]);
  return (
    <section className={`dark2 ${styles.news}`}>
      <div className="container">
        <div className={styles.content}>
          <div className={styles.header}>
            <Title>
              <h3>{data_[selectedLang].title}</h3>
            </Title>
            <Link to={routes.news.path} className={styles["read-more"]}>
              {data_[selectedLang].all_news}
              <RightArrowIcon />
            </Link>
          </div>
          <div className={styles.body}>
            {boolean ? (
              <Swiper
                slidesPerView={"auto"}
                spaceBetween={gap}
                className="mySwiper-news"
              >
                {generateNews()}
              </Swiper>
            ) : (
              generateNews()
            )}
          </div>
          {boolean ? (
            <Link to={routes.news.path} className={styles["read-more-bottom"]}>
              {data_[selectedLang].all_news}
            </Link>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default News;
