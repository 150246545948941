import { useState } from "react";
import styles from "./index.module.scss";
import Zoom from "./Zoom";

const ImageCol = ({ images }) => {
  const [open, setOpen] = useState(false);
  const [sortedArray, setSortedArray] = useState([]);
  const [overlay, setOverlay] = useState([]);
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  const arrSort = (img) => {
    const objWithIdIndex = images.findIndex((obj) => obj.id === img.id);
    const one = images.slice(0, objWithIdIndex);
    const two = images.slice(objWithIdIndex);
    setOverlay("overlay");
    setSortedArray([...two, ...one]);
  };
  return (
    <div className={`dark ${styles["gallery"]}`}>
      <div className={styles["container"]}>
        <div className={styles["content"]}>
          {images.map((item, i) => (
            <div
              className={styles["image-col"]}
              key={i}
              onClick={() => {
                setOpen(true);
                arrSort(item);
              }}
            >
              <img src={linkBuilder(item.image)} />
            </div>
          ))}
        </div>
      </div>
      <div>
        {open ? (
          <Zoom
            click={() => {
              setOpen(false);
              setOverlay([]);
            }}
            images={sortedArray}
          />
        ) : null}
      </div>
      <div className={overlay}></div>
    </div>
  );
};
export default ImageCol;
