import styles from './index.module.scss'
import {LeftSlideIcon, RightSlideIcon} from "../../../icons"
import {useRef} from 'react'
import {Link} from "react-router-dom"

const Slide = ({img, title, i, id, n, margin}) => {
    const slideRef = useRef(null)
    const linkBuilder = url => {
        return process.env.REACT_APP_STORAGE + url
    }
    const handleBeforeSlide = () => {
        if (i === 0) {
            return;
        }
        let newMargin = (i - 1) * margin
        slideRef.current.previousElementSibling.style.left = `${newMargin}%`;
    }
    const handleCurrentSlide = () => {
        if (i === n - 1) {
            return;
        }
        let newMargin = i * margin - 60 + margin;
        slideRef.current.style.left = `${margin === 0 ? -120 : newMargin}%`;
    }
    const handleImages = () => {
        let slides = slideRef.current.parentElement.childNodes;
        for(let k=i;k<n-1;k++) {
            slides[k].style.left = `${k*margin}%`;
        }
        for(let k=0;k<i;k++) {
            let newMargin = k * margin - 60 + margin;
            slides[k].style.left = `${margin === 0 ? -120 : newMargin}%`;
        }
    }

    return (
        <div ref={slideRef} className={styles.slide} style={{zIndex: (n - i), left: `${i * margin}%`}}>
            <div>
                <Link to={`/type/${id}`}>{title}</Link>
                <div>
                    <LeftSlideIcon onClick={() => handleBeforeSlide()}/>
                    <RightSlideIcon onClick={(e) => {
                        handleCurrentSlide()
                    }}/>
                </div>
            </div>
            <img onClick={() =>handleImages()}src={linkBuilder(img)} alt={title}/>
        </div>
    )
}

export default Slide
