import styles from './index.module.scss'
import {Link} from "react-router-dom";

const Button = ({children, className, rightTrapezoid,click}) => {
    return (
        <button onClick={click} className={`${styles.button} ${className} ${rightTrapezoid ?styles.right : styles.left}`}>{children}</button>
    )
}

const LinkButton = ({children, className, to,rightTrapezoid}) => {
    return (
        <Link to={to} className={`${styles.button} ${className} ${rightTrapezoid ? styles.right :styles.left}`}>{children}</Link>
    )
}
const LinkButton2 = ({children, className, to}) => {
    return (
        <Link to={to} className={`${styles.button} ${className} `}>{children}</Link>
    )
}

const FormButton = ({children,type}) => {
    return (
        <button type={type} className={styles.button}>{children}</button>
    )
}
export {
    Button,
    LinkButton,
    LinkButton2,
    FormButton
}
