import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "./styles.css";
import {PlayIcon} from '../../../icons'
import styles from './index.module.scss'

const Photos = ({data,video}) => {
    const linkBuilder = url => {
        return process.env.REACT_APP_STORAGE + url
    }
    const [current, setCurrent] = useState({})
    useEffect(() => {
        setCurrent({img: linkBuilder(data[0]?.image?data[0].image:data[0]), video: false})
    },[data])
    return (
        <div className={`dark ${styles['mobile-slide']}`}>
            {current.video?
            <iframe
            src={`https://www.youtube.com/embed/${video}`}>
            </iframe>:
            <figure>
                <img src={current.img?current?.img:current}/>
            </figure>
            }

            <Swiper
                slidesPerView={"auto"}
                spaceBetween={16}
                className="news-internal-slide"
            >
                {video?
                  <SwiperSlide 
                  onClick={() => {setCurrent({video: true})}} 
                  className="video-slide-item"
                  >
                      <img src={linkBuilder(data[0]?.image?data[0].image:data[0])} alt="img"/>
                      <div><PlayIcon/></div>
                  </SwiperSlide>:null}
                {
                    data?.map((item, i) => (
                    (i===0&&video)?null:
                    <SwiperSlide 
                    onClick={() => {setCurrent({img: linkBuilder(item?.image?item.image:item)})}} 
                    key={i}
                    >
                        <img src={linkBuilder(item?.image?item.image:item)} alt="img"/>
                    </SwiperSlide>
                   ))
                }
            </Swiper>
        </div>
    )
}
export default Photos