import axios from '../index'

const getSixMeasures = query => {
    return axios.get(`six-measures${query}`)
}

const getData = query => {
    return axios.get(`all-measures${query}`)
}

export default {getSixMeasures,getData}