import styles from './index.module.scss'
import ContactItem from '../../components/pages/contactItem'
import InnerPageHeader from "../../components/pages/innerPageHeader"
import text from './text.json'
import Breadcrumb from '../../components/core/breadcrumb'
import PageTitle from '../../components/core/pageTitle'
import Table from '../../components/core/table'
import {useEffect, useState} from "react";
import AthletesService from '../../api/athletes'
import Loading from '../../components/pages/loading'
import { MainContext, useContext } from '../../context'


const Athletes = () => {
   const [load,SetLoad] = useState(false)
   const [girls_tabs,setGirls_tabs] = useState([])
   const [boys_tabs,setBoys_tabs] = useState([])
   const {selectedLang} = useContext(MainContext)

   useEffect(async () => {
      const query = `?lang=${selectedLang}`
       const {data} = await AthletesService.getAll(query)
       const athletes = (id, fullname, birth ,photo, description) => {
          return {
              id,
              fullname,
              birth,
              photo,
              description
          }
       }
       const linkBuilder = url => {
         return process.env.REACT_APP_STORAGE + url
       }
      setGirls_tabs(data.qizlar.map(item=>athletes(item.id,item.name + " " + item.surname,item.birth_year,linkBuilder(item.image),item?.description)))
      setBoys_tabs(data.oglanlar.map(item=>athletes(item.id,item.name + " " + item.surname,item.birth_year,linkBuilder(item.image),item?.description)))
      SetLoad(true)
   },[selectedLang])

   const tabs = {
      "az": [
            {
               "tab":"Oğlanlar",
               "data": boys_tabs,
               "link" : "/"
            },
            {
               "tab":"Qızlar",
               "data":girls_tabs,
               "link" : "/"
            }
      ],
      "en": [
            {
               "tab":"Boys",
               "data": boys_tabs,
               "link" : "/"
            },
            {
               "tab":"Girls",
               "data":girls_tabs,
               "link" : "/"
            }
      ],
      "ru": [
            {
               "tab":"Мальчики",
               "data": boys_tabs,
               "link" : "/"
            },
            {
               "tab":"Девочки",
               "data":girls_tabs,
               "link" : "/"
            }
      ]
   }
   return (
      load?
      <section className={styles.athletes}>

      <InnerPageHeader/>
      
      <div className={styles.text}>
         <Breadcrumb crumbs={text[selectedLang].breadcrumbs} />
         <PageTitle>{text[selectedLang].text}</PageTitle>
      </div>
  
      <div className={styles['tabs-container']}>
         <Table tabs={tabs[selectedLang]} component={"athlete"} />
      </div>

      <ContactItem bg={false} />
      </section>: <Loading/>
   )
}

export default Athletes