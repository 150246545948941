import { routeArr } from "./routes";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/layouts/navbar";
import Footer from "./components/layouts/footer";
import { MainContext } from "./context";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const App = () => {
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "az"
  );
  const data = {
    selectedLang,
    setSelectedLang,
  };

  useEffect(() => {
    document.querySelector("html").setAttribute("lang", selectedLang);
  }, [selectedLang]);
  return (
    <MainContext.Provider value={data}>
      <BrowserRouter>
        <Routes>
          {routeArr.map((item) => (
            <Route
              exact
              path={item.path}
              key={item.id}
              element={
                <>
                  <Navbar
                    title={item.title}
                    isTransparent={item.path === "/" || "/canoe"}
                  />
                  <item.component />
                  <Footer footerBg={item.footerBg} />
                </>
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </MainContext.Provider>
  );
};

export default App;
