import styles from './index.module.scss'
import {CloseIcon} from '../../../icons'
const Video = ({src,click}) => {
   return (
      <div className={styles.video}>
         <video src={src}  controls>
            <source src={src} type="video/mp4"/>
            Your browser does not support the video tag.
         </video>
         <span onClick={click}>Bağla <CloseIcon/></span>
      </div>
   )
}

export default Video