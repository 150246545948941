import data from "./text.json";
import Home from "./pages/Home";
import News from "./pages/news";
import NewsInternal from "./pages/newsInternal";
import NationalTeams from "./pages/nationalTeams";
import Contact from "./pages/contact";
import Athletes from "./pages/athletes";
import Lead from "./pages/lead";
import Canoe from "./pages/canoe";
import History from "./pages/history";
import AzerbaijanHistory from "./pages/AzerbaijanHistory";
import Achievements from "./pages/achievements";
import Gallery from "./pages/gallery";
import Calendar from "./pages/calendar";
import Announcement from "./pages/announcement";
import AnnouncementInternal from "./pages/AnnouncementInternal";
import Training from "./pages/training";
import TrainingInternal from "./pages/trainingInternal";
import Search from "./pages/search";
import GalleryFolders from "./pages/galleryFolders";
import Federation from "./pages/federation"

const routeItem = (id, title, path, component, footerBg = false) => {
  return {
    id,
    title,
    path,
    component,
    footerBg,
  };
};

const routes = {
  home: routeItem(1, data.avar_cekme, "/", Home, true),
  news: routeItem(2, data.avar_cekme_news, "/news", News),
  news_internal: routeItem(
    3,
    data.avar_cekme_news_internal,
    "/news/:id",
    NewsInternal
  ),
  news_national_teams: routeItem(
    4,
    data.avar_cekme_news_national_teams,
    "/national-teams",
    NationalTeams
  ),
  news_our_athletes: routeItem(
    5,
    data.avar_cekme_news_athletes,
    "/our-athletes",
    Athletes
  ),
  join_us: routeItem(6, data.avar_cekme, "/join-us", Home, true),
  contact: routeItem(7, data.avar_cekme_contact, "/contact", Contact, true),
  lead: routeItem(8, data.avar_cekme_lead, "/lead", Lead),
  canoe: routeItem(10, data.avar_cekme_canoe, "/type/:id", Canoe),
  history: routeItem(11, data.avar_cekme_history, "/history", History),
  history_az: routeItem(
    12,
    data.avar_cekme_history_az,
    "/history-az",
    AzerbaijanHistory
  ),
  achievements: routeItem(
    13,
    data.avar_cekme_achievements,
    "/achievements",
    Achievements
  ),
  gallery: routeItem(14, data.avar_cekme_gallery, "/gallery", Gallery),
  calendar: routeItem(15, data.avar_cekme_calendar, "/all-events", Calendar),
  announcement: routeItem(
    16,
    data.avar_cekme_announcement,
    "/announcement",
    Announcement
  ),
  announcementInternal: routeItem(
    17,
    data.avar_cekme_announce_intenral,
    "/announcement/:id",
    AnnouncementInternal
  ),
  training: routeItem(18, data.avar_cekme_training, "/training", Training),
  training_internal: routeItem(
    19,
    data.avar_cekme_training_internal,
    "/training/:id",
    TrainingInternal
  ),
  search: routeItem(20, data.search, "/search/:searched", Search),
  gallery_folders: routeItem(
    21,
    data.avar_cekme_gallery_folders,
    "/gallery/:id",
    GalleryFolders
  ),
  federation: routeItem(
    22,
    data.avar_cekme_federation,
    "/federation",
    Federation
    
  ),
};

const routeArr = Object.values(routes);

export { routes, routeArr };
