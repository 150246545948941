import {BreadcrumbArrow} from '../../../icons'
import styles from './index.module.scss'
import {Link} from 'react-router-dom'

const Breadcrumb = ({crumbs}) => {
   return(
      <div className={styles['breadcrumb-container']}>
          {crumbs.map((crumb,i) =>(
            i===0?<Link to={crumb.link}>{crumb.text}</Link>:<div><BreadcrumbArrow /><Link to={crumb.link}>{crumb.text}</Link></div>
          ))}
      </div>
   )
}

export default Breadcrumb