import { useState } from "react";
import styles from "./index.module.scss";
import "swiper/css/bundle";
import Slider from "../../../components/core/slider";
import Zoom from "./Zoom";
import Iframe from "../../../pages/Home/iframe";
import { PlayIcon } from "../../../icons";

const Memories = ({ images, video }) => {
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  document.querySelectorAll(".dark").forEach((item) => {
    item.style.filter = visible || open ? "brightness(60%)" : null;
  });
  document.querySelectorAll(".dark2").forEach((item) => {
    item.style.backgroundColor = visible || open ? "#999" : null;
    item.style.border = visible || open ? "none" : null;
  });
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  return (
    <div className={styles.parent}>
      {open ? (
        <Zoom
          click={() => {
            setOpen(false);
          }}
          images={images}
          video={video}
          poster={linkBuilder(images[1]?.image)}
        />
      ) : null}
      {visible ? (
        <Iframe
          link={video}
          close={() => {
            setVisible(false);
          }}
        />
      ) : null}
      <div className={`dark ${styles["memories-photos"]}`}>
        <div>
          <Slider images={images} />
        </div>

        <div>
          {images[1]?.image ? (
            <figure>
              <img src={linkBuilder(images[1]?.image)} />
              {video ? (
                <div
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  <PlayIcon />
                </div>
              ) : null}
            </figure>
          ) : null}

          <figure
            onClick={() => {
              setOpen(true);
            }}
          >
            <img src={linkBuilder(images[0]?.image)} />
            {images.length === 1 ? (
              <p>1 foto</p>
            ) : (
              <p>+{images?.length - 1} foto</p>
            )}
          </figure>
        </div>
      </div>
    </div>
  );
};
export default Memories;
