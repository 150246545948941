import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import ContactItem from "../../components/pages/contactItem";
import ImageInfo from "../../components/core/imageInfo";
import img1 from "../../assets/images/canoe/01.webp";
import img2 from "../../assets/images/canoe/02.webp";
import history from "../../assets/images/history.svg";
import text from "./text.json";
import HomeService from "../../api/home";
import Loading from "../../components/pages/loading";
import { MainContext, useContext } from "../../context";

const AzerbaijanHistory = () => {
  const [load, SetLoad] = useState(false);
  const [data, setData] = useState([]);
  const { selectedLang } = useContext(MainContext);
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await HomeService.getSportData(3, query);
    setData(data);
    SetLoad(true);
  }, [selectedLang]);
  return load ? (
    <section className={styles.container}>
      <p>{data?.middlepart_description}</p>
      <figure>
        <img src={img1} />
        <img src={img2} />
      </figure>
      <div>
        <ImageInfo
          photo2={img2}
          photo1={img1}
          // photo2={linkBuilder(data?.toppart_bottom_image)}
          // photo1={linkBuilder(data?.toppart_top_image)}
          title={data?.title}
          header={data?.toppart_title}
          text={data?.toppart_description}
        />
      </div>
      <div>
        <div>
          <h2>{data?.middlepart_title}</h2>
          <p>{data?.middlepart_description}</p>
        </div>
        <figure>
          <img src={history} alt={data?.middlepart_title} />
        </figure>
      </div>
      <ContactItem bg={false} />
    </section>
  ) : (
    <Loading />
  );
};

export default AzerbaijanHistory;
