import styles from "./index.module.scss";
import HeroIcon from "../../../assets/images/hero-icon.svg";
import HeroText from "../../../assets/images/hero-text.svg";
import HomeService from "../../../api/home";
import { useRef, useState, useEffect } from "react";
import Loading from "../../../components/pages/loading";

const Hero = ({ data }) => {
  const [load, SetLoad] = useState(false);
  const [active, setActive] = useState(false);
  const [images, setImages] = useState([]);
  const [poster, setPoster] = useState();
  const bg = useRef(null);
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  useEffect(async () => {
    setPoster(linkBuilder(data[0]?.image));
    let i = 0,
      j = 1,
      n = data?.length - 1;
    let arr = [];
    while (i < 20) {
      arr.push(linkBuilder(data[j]?.image));
      if (j == n) {
        j = -1;
      }
      ++j;
      i++;
    }
    setImages(arr);
    SetLoad(true);
  }, [data]);
  return (
    <section className={`dark2 ${styles.hero}`}>
      <div
        className={`${styles["main-bg"]} ${active && styles.active}`}
        style={{ backgroundImage: `url(${poster})` }}
      />
      <div ref={bg} className={`${styles.bg} ${active && styles.active}`}>
        {images.map((item, index) => (
          <div
            key={index}
            className={styles.slide}
            style={{ backgroundImage: `url(${item})` }}
          />
        ))}
      </div>
      <div
        className={styles.icon}
        onMouseEnter={() => setActive(!active)}
        onMouseLeave={() => setActive(!active)}
      >
        <img src={HeroIcon} alt="Avarçəkmə" />
        <img
          src={HeroText}
          className={active && styles.active}
          alt="Avarçəkmə"
        />
      </div>
    </section>
  );
};

export default Hero;
