import LogoBlue from "../../../assets/images/logo-blue.svg";
import LogoBlueEng from "../../../assets/images/logo-blue-eng.svg";
import { MainContext, useContext } from "../../../context";
const Loading = () => {
  const { selectedLang } = useContext(MainContext);

  return (
    <div className="loading">
      <img
        src={selectedLang == "en" ? LogoBlueEng : LogoBlue}
        alt={"Avarçəkmə"}
      />
    </div>
  );
};

export default Loading;
