import styles from './index.module.scss';
import text from './text.json';
import InnerPageHeader from '../../components/pages/innerPageHeader';
import AchievementsItem from '../../components/pages/achievementsItem';
import PageTitle from '../../components/core/pageTitle';
import Breadcrumb from '../../components/core/breadcrumb';
import { MainContext, useContext } from '../../context';
import { useEffect } from 'react';
import achievements from '../../api/achievements';
import { useState } from 'react';
import Loading from '../../components/pages/loading';

const Achievements = () => {
  const { selectedLang } = useContext(MainContext);
  const [archData, setArchData] = useState();
  const [load, setLoad] = useState(false);

  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await achievements.getAchievements(query);
    setArchData(data);
    setLoad(true);
  }, [selectedLang]);
  return load ? (
    <section className={styles.achievements}>
      <InnerPageHeader />
      <div className={styles.content}>
        <Breadcrumb crumbs={text[selectedLang].breadcrumbs} />
        <PageTitle>{text[selectedLang].title}</PageTitle>
        <AchievementsItem data={archData} />
      </div>
    </section>
  ) : (
    <Loading />
  );
};

export default Achievements;
