import styles from './index.module.scss'
import {CloseIcon,RightArrowIcon,LeftArrowIcon} from '../../../icons'

const Module1 = ({img,text,fullname,active,close,prev,next,prevDis,nextDis}) => {
   return (
      <div className={styles.container} style={active?{display:'block'}:{display:'none'}}>
         <img src={img} alt={fullname} />
         <div>
            <div><CloseIcon onClick={close} /></div>
            <h2>{fullname}</h2>
            <p>{text}</p>
            <div>
               <button className={prevDis?null:styles.prev} style={prevDis?{cursor:"not-allowed",opacity:"0.6"}:null} onClick={prev}><LeftArrowIcon />Əvvəlki</button>
               <button className={nextDis?null:styles.next} style={nextDis?{cursor:"not-allowed",opacity:"0.6"}:null} onClick={next}>Növbəti<RightArrowIcon /></button>
            </div>
         </div>
      </div>
   )
}
const Module2 = ({img,text,fullname,active,close}) => {
   return (
      <div className={`${styles.container} ${styles.module}`} style={active?{display:'flex'}:{display:'none'}}>
         {img?<img src={img} alt={fullname} />:null}
         <div style={!img?{width:"100%"}:null}>
            <div><CloseIcon onClick={close} /></div>
            <h2>{fullname}</h2>
            <p>{text}</p>
         </div>
      </div>
   )
}
const Module3 = ({text,fullname,active,close}) => {
   return (
      <div className={`${styles.container} ${styles.module3}`} style={active?{display:'flex'}:{display:'none'}}>
         <div style={{width:"100%"}}>
            <div><CloseIcon onClick={close} /></div>
            <h2>{fullname}</h2>
            <p>{text}</p>
         </div>
      </div>
   )
}

export {
   Module1,
   Module2,
   Module3
}