import styles from './index.module.scss';
import text from './text.json'
import {useState,useEffect} from 'react';
import InnerPageHeader from "../../components/pages/innerPageHeader";
import PageTitle from "../../components/core/pageTitle";
import Breadcrumb from "../../components/core/breadcrumb";
import {EventsCard} from '../../components/core/cards'
import ContactItem from '../../components/pages/contactItem'
import MeasuresService from '../../api/measures';
import Loading from '../../components/pages/loading'
import {Module3} from '../../components/core/module'
import { MainContext, useContext } from '../../context';

const Calendar = () => {
   const [active, setActive] = useState(false)
   const [index, setIndex] = useState(1)
   const [load,SetLoad] = useState(false)
   const [events,setEvents] = useState([])
   const {selectedLang} = useContext(MainContext)

   useEffect(async ()=> {
      const query = `?lang=${selectedLang}`
      const {data} = await MeasuresService.getData(query)
      setEvents(data)
      SetLoad(true)
   },[selectedLang])
   document.querySelectorAll(".dark").forEach((item)=>{
      item.style.filter = active ? "brightness(60%)" : null;
  })
   return (
      load?
      <section className={styles.parent}>
           <Module3
               fullname={events[index]?.title} 
               text={events[index]?.description}
               active={active}
               close={() => {
                  setActive(false)
               }}
            />
            <div style={active?{backgroundColor:"#fff"}:null} className={`dark ${styles.container}`}>
            <InnerPageHeader/>
            <Breadcrumb crumbs={text[selectedLang].breadcrumbs}/>
            <PageTitle>{text[selectedLang].title}</PageTitle>

            <div className={styles.content}>
               {events.map((event,i)=>(<EventsCard set={()=>{setIndex(i);setActive(true)}} key={event.id} event={event} />))}
            </div>
            <ContactItem bg={false}/>
            </div>
      </section>
       : <Loading/>
   )
}
export default Calendar