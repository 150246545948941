import styles from './index.module.scss'
import staticWords from './../../../../translation.json'
import {Link} from "react-router-dom";
import {RightArrowIcon} from "../../../../icons";
import Helpers from "../../../../utils/helpers";
import {MainContext, useContext} from '../../../../context'

const EventsCard = ({event,set}) => {
    const {selectedLang} = useContext(MainContext)
    return (
        <>
         <div onClick={set} className={styles['events-card']}>
            <div className={styles.body}>
                <div className={styles.header}>
                    <span>{event?.country_city}</span>
                    <span>{event?.date}</span>
                </div>
                <p className={styles.content}>
                    {Helpers.textLengthChecker(event?.title,44)}
                </p>
            </div>
            <div className={styles.footer}>
                <Link to={`#`}>
                    {staticWords[selectedLang].more}
                    <RightArrowIcon/>
                </Link>
            </div>
         </div>
        
        </>

    )
}

export default EventsCard