import styles from './index.module.scss';
import text from './text.json'
import InnerPageHeader from "../../components/pages/innerPageHeader";
import PageTitle from "../../components/core/pageTitle";
import Breadcrumb from "../../components/core/breadcrumb";
import ContactItem from '../../components/pages/contactItem'
import img from '../../assets/images/announcement.png'
import {AnnouncementCard} from '../../components/core/cards'
import {useEffect, useState} from 'react';
import AnnouncementService from '../../api/announcement';
import Loading from '../../components/pages/loading'
import { MainContext, useContext } from '../../context';

const Announcement = () => {
   const [load,SetLoad] = useState(false)
   const [data,setData] = useState([])
   const {selectedLang} = useContext(MainContext)
   useEffect(async () => {
      const query = `?lang=${selectedLang}`
      const {data} = await AnnouncementService.getData(query)
      setData(data)
      SetLoad(true)
   },[selectedLang])
   return (
      load?
      <section className={styles.container}>
      <InnerPageHeader/>
      <Breadcrumb crumbs={text[selectedLang].breadcrumbs}/>
      <PageTitle>{text[selectedLang].title}</PageTitle>

      <div className={styles.content}>
         {data.map((item,i)=>(<AnnouncementCard key={i} data={item} />))}
      </div>

      <ContactItem bg={false}/>
      </section> : <Loading/>
   )
}
export default Announcement