import styles from './index.module.scss'

const Title = ({ children, className }) => {
    return (
        <header className={styles.title}>
            {children}
        </header>
    )
}

export default Title
