import styles from "./index.module.scss";
import data_ from "./data.json";
import Title from "../../../components/core/title";
import { LinkButton } from "../../../components/core/button";
import { Link } from "react-router-dom";
import { RightArrowIcon } from "../../../icons";
import { EventsCard } from "../../../components/core/cards";

import React, { useState, useLayoutEffect, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Module3 } from "../../../components/core/module";
import "./style.css";
import { MainContext, useContext } from "../../../context";

const Events = ({ data }) => {
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(1);
  const [boolean, setBoolean] = useState(false);
  const [gap, setGap] = useState(0);
  const [events, setEvents] = useState([]);
  const { selectedLang } = useContext(MainContext);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 768) {
        setGap(10);
        setBoolean(true);
        return;
      }
      setBoolean(false);
    }

    window.addEventListener("resize", updateSize);
    updateSize();
  }, []);
  useEffect(async () => {
    setEvents(data);
  }, [data]);
  document.querySelectorAll(".dark2").forEach((item) => {
    item.style.filter = active ? "brightness(60%)" : null;
  });
  return (
    <section className={styles.parent}>
      <Module3
        fullname={events[index]?.title}
        text={events[index]?.description}
        active={active}
        close={() => {
          setActive(false);
        }}
      />
      <div className={`dark2 ${styles.events}`}>
        <div className="container">
          <div className={styles.content}>
            <div className={styles.header}>
              <Title>
                <h5>{data_[selectedLang].events}</h5>
              </Title>
              <LinkButton
                to={"/all-events"}
                rightTrapezoid={true}
                className={styles.button}
              >
                <span>
                  {data_[selectedLang].all_events}
                  <RightArrowIcon />
                </span>
              </LinkButton>
            </div>
            <div className={styles.body}>
              {boolean ? (
                <Swiper
                  slidesPerView={"auto"}
                  spaceBetween={gap}
                  className="mySwiper-event"
                >
                  {events.map((item, i) => (
                    <SwiperSlide>
                      <EventsCard
                        set={() => {
                          setIndex(i);
                          setActive(true);
                        }}
                        event={item}
                        key={item?.id}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                events.map((item, i) => (
                  <EventsCard
                    set={() => {
                      setIndex(i);
                      setActive(true);
                    }}
                    event={item}
                    key={item?.id}
                  />
                ))
              )}
            </div>
            {boolean ? (
              <Link to={"/all-events"} className={styles["read-more-bottom"]}>
                {data_[selectedLang].all_events}
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Events;
