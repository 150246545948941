import axios from "../index";

const getHomeNews = query => {
    return axios.get(`three-news${query}`)
}

const getAllNews = query => {
    return axios.get(`all-news${query}`)
}

const getNewsDetailById = (id, query) => {
    return axios.get(`news/${id}${query}`)
}

export default {getHomeNews, getAllNews, getNewsDetailById}