import {useLayoutEffect, useState} from 'react'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import styles from './index.module.scss'
import {Swiper, SwiperSlide} from "swiper/react";
import './style.css'
import "swiper/css";
import {LinkButton} from '../button'
import {DownArrowIcon, HeaderArrow} from '../../../icons'
import text from './text.json'
import {Module1} from '../module'
import {AthleteCard} from '../cards'

const Table = ({tabs, component}) => {
    const [active, setActive] = useState(false)
    const [i, setI] = useState(-1)
    const [j, setJ] = useState(-1)
    const len = tabs[j]?.data.length
    const [width, setWidth] = useState(false)
    useLayoutEffect(() => {
        function updateSize() {
            if (window.innerWidth <= 768) {
                setWidth(true)
                return
            }
            setWidth(false)
        }

        window.addEventListener('resize', updateSize);
        updateSize();
    }, []);
    document.querySelectorAll(".dark").forEach((item)=>{
        item.style.filter = active ? "brightness(60%)" : null;
    })
    return (
        <>
            {(width && component === "team") ?
                <div className={` dark ${styles.container}`}>
                    {tabs.map((item, m) =>
                        <div key={`team-slide-${m}`} className={styles.team}>
                            <h3><HeaderArrow/> {item.tab} </h3>
                            <Swiper
                                slidesPerView={"auto"}
                                spaceBetween={16}
                                className="team-swiper"
                            >
                                {
                                    item.data.length>0 && item.data.map((datum, n) => (
                                        <SwiperSlide key={n}>
                                            <AthleteCard key={n}
                                                         click={() => {
                                                             setI(n);
                                                             setJ(m);
                                                             setActive(true)
                                                         }}
                                                         className={(j === m && i === n) ? true : false}
                                                         fullname={datum.fullname}
                                                         title={`${datum.birth} təvəllüd`}
                                                         photo={datum.photo}
                                            />
                                        </SwiperSlide>
                                    ))
                                }
                            </Swiper>
                        </div>
                    )}
                </div>
                :
                <Tabs className={`table dark ${component}`}>

                    <TabList>
                        {tabs.map((item, k) => (
                            <Tab onClick={() => {
                                setJ(k);
                                setI(0)
                            }} key={k}>{item.tab}</Tab>
                        ))}
                    </TabList>


                    {
                        tabs.map((item, m) => (
                            <TabPanel key={m}>

                                <div tabIndex="1">
                                    {item.data.length>0 && item.data.map((datum, n) => (
                                        <AthleteCard key={n}
                                                     click={() => {
                                                         setI(n);
                                                         setJ(m);
                                                         setActive(true)
                                                     }}
                                                     className={(j === m && i === n) ? true : false}
                                                     fullname={datum.fullname}
                                                     title={`${datum.birth} təvəllüd`}
                                                     photo={datum.photo}
                                        />
                                    ))}
                                </div>

                                {/* <LinkButton
                                    to={item.link}
                                    rightTrapezoid={true}
                                >
                                    {text.link}
                                    <DownArrowIcon/>
                                </LinkButton> */}

                            </TabPanel>
                        ))
                    }

                </Tabs>
            }


            <Module1
                active={active}
                close={() => {
                    setActive(false);
                    setI(-1);
                    setJ(-1)
                }}
                prev={() => {
                    i === 0 ? setI(i) : setI(i - 1)
                }}
                next={() => {
                    i === len - 1 ? setI(i) : setI(i + 1)
                }}
                prevDis={i === 0 ? true : false}
                nextDis={i === len - 1 ? true : false}
                img={tabs[j]?.data[i]?.photo}
                text={tabs[j]?.data[i]?.description}
                fullname={tabs[j]?.data[i]?.fullname}
            />
        </>
    )
}

export default Table