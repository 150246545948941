import axios from "../index";

const getData = (query) => {
  return axios.get(`galery${query}`);
};

const getFolderDetailById = (id) => {
  return axios.get(`gelery/${id}$`);
};
export default { getData, getFolderDetailById };
