import { FormButton } from "../../../components/core/button";
import Input from "../../../components/core/input";
import Textarea from "../../../components/core/textarea";
import data_ from "./data.json";
import { RightArrowIcon } from "../../../icons";
import { useEffect, useState } from "react";
import AppealsService from "../../../api/appeals";
import { MainContext, useContext } from "../../../context";

const FormRight = ({ data }) => {
  const [types, setTypes] = useState([]);
  const { selectedLang } = useContext(MainContext);
  const inputItem = (id, type, placeholder) => {
    return {
      id,
      type,
      placeholder,
    };
  };
  const inputs = [
    inputItem("fullname", "text", data_[selectedLang].appeal_right_form_name),
    inputItem("email", "email", data_[selectedLang].appeal_right_form_email),
  ];
  const sendAppeal = async (e) => {
    e.preventDefault();
    const formData = {
      fullname: document.querySelector("#fullname").value,
      email: document.querySelector("#email").value,
      text: document.querySelector("#text").value,
      appeals_id: document.querySelector("#type").value,
    };
    const data = await AppealsService.sendAppeal(formData);
    if (data) {
      alert("Müarciətiniz uğurla göndərildi");
    }
  };
  useEffect(async () => {
    setTypes(data);
  }, [data]);
  return (
    <div>
      <form onSubmit={sendAppeal}>
        {inputs.map((item) => (
          <Input
            key={item.id}
            id={item.id}
            type={item.type}
            placeholder={item.placeholder}
          />
        ))}
        <select id="type">
          <option disabled selected hidden>
            {data_[selectedLang].appeal_right_form_select}
          </option>
          {types?.map((item, i) => (
            <option key={i} value={item?.id}>
              {item?.name}
            </option>
          ))}
        </select>
        <Textarea
          name="text"
          placeholder={data_[selectedLang].appeal_right_form_text}
        />
        <FormButton type="submit">
          <span>
            {data_[selectedLang].appeal_right_form_button}
            <RightArrowIcon />
          </span>
        </FormButton>
      </form>
    </div>
  );
};

export default FormRight;
