import React, {useRef, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";
import {Navigation} from "swiper";

export default function Slider({images}) {
    const linkBuilder = url => {
        return process.env.REACT_APP_STORAGE + url
    }
    return (
        <>
            <Swiper navigation={true} modules={[Navigation]}>
                {
                    images.map((image, i) => (
                        <SwiperSlide key={`slide-img-${i}`}><img src={linkBuilder(image?.image)}/></SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    );
}
