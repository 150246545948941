import styles from './index.module.scss'
import {Link} from 'react-router-dom'
const HeaderIcon = ({children,link}) => {
   return (
      <a className={styles.icon} target="_blank" href={link}>
         {children}
      </a>
   )
}

export default HeaderIcon