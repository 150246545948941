import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ImageCol from "./imageCol";
import GalleryService from "../../api/gallery";
import Loading from "../../components/pages/loading";
import { MainContext, useContext } from "../../context";

const GalleryFolders = () => {
  const [load, SetLoad] = useState(false);
  const { id } = useParams();
  const [images, setImages] = useState({});
  const { selectedLang } = useContext(MainContext);

  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await GalleryService.getData(query);
    await setImages(data.sekiller.find((x) => x.id == id));
    await SetLoad(true);
  }, []);
  return load ? <ImageCol images={images.images} /> : <Loading />;
};

export default GalleryFolders;
