import {useState,useEffect} from 'react'
import staticWords from '../../../translation.json'
import styles from './index.module.scss'
import {RightArrowIcon} from '../../../icons'
import {LinkButton} from '../button'
import Iframe from '../../../pages/Home/iframe'
import VideoItem from '../video'
import { MainContext, useContext } from '../../../context'

const ImageInfo = ({photo1,photo2,title,header,text,button,video}) => {
    const [visible,setVisible] = useState(false)
    const {selectedLang} = useContext(MainContext)
    // let header2 = header.split(" ");
   // header2[0] = "<b>" + header2[0] + "</b>";
   // header2[1] = "";
   // header2 = header2.join(" ")
   document.querySelectorAll(".dark").forEach((item)=>{
      item.style.filter = visible ? "brightness(60%)" : null;
  })
   return (
      <div style={visible?{backgroundColor:"#999"}:null} className={styles.parent}>
         {visible?<Iframe link={video} close={()=>{setVisible(false)}}/>:null}
         <div className={`dark ${styles.container}`}>


         <div className={`${styles.content}`}>

               <div>
                 {video?<VideoItem poster={photo1} click={()=>{setVisible(true)}}/>:<img src={photo1} alt="Avarçəkmə" />}
                  <img src={photo2} alt="Avarçəkmə"/>
               </div>

               <div>
                  <div>
                     <p>{title}</p>
                     <h2 dangerouslySetInnerHTML={{__html:header}}></h2>
                  </div>
                  <p dangerouslySetInnerHTML={{__html:text}}></p>
                  {button?<LinkButton rightTrapezoid={true} to={'/contact'}><span>{staticWords[selectedLang].join_us} <RightArrowIcon/></span></LinkButton>:null}
               </div>

         </div>
         
         </div>
      </div>

   )
}

export default ImageInfo