import styles from "./index.module.scss";
import text from "./text.json";
import ContactIcon from "../../../components/core/contactIcon";
import { LinkButton2 } from "../../../components/core/button";
import {
  PhonesIcon,
  AddressIcon,
  MailIcon,
  RightArrowIcon,
} from "../../../icons";
import PageTitle from "../../core/pageTitle";
import { useEffect, useState } from "react";
import ContactService from "../../../api/contact";
import { MainContext, useContext } from "../../../context";

const ContactItem = ({ bg = true, title = true, button = true }) => {
  const [contactinfo, setContactinfo] = useState([]);
  const { selectedLang } = useContext(MainContext);

  const infoItem = (id, icon, title) => {
    return {
      id,
      icon,
      title,
    };
  };
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await ContactService.getContactInfo(query);
    setContactinfo([
      infoItem(1, PhonesIcon, data?.mobile_number),
      infoItem(2, AddressIcon, data?.address),
      infoItem(3, MailIcon, data?.email),
    ]);
  }, [selectedLang]);
  return (
    <div
      className={`dark ${styles.contact}`}
      style={{ background: bg ? "#fff" : "#F5F6FA" }}
    >
      {title ? <PageTitle>{text.title}</PageTitle> : null}
      {contactinfo.map((item) => (
        <div key={item.id}>
          <ContactIcon key={item.id}>{<item.icon />}</ContactIcon>
          <p>{item.title}</p>
        </div>
      ))}
      {button ? (
        <LinkButton2 to={"/contact"}>
          <span>
            {text.button}
            <RightArrowIcon />
          </span>
        </LinkButton2>
      ) : null}
    </div>
  );
};
export default ContactItem;
