import styles from './index.module.scss'

const AthleteCard = ({photo, fullname, title, click, hasDuty = false}) => {
    return (
        <div onClick={click} className={styles.container}>

            <img src={photo} alt={fullname}/>

            <div>
                <p>{fullname}</p>
                {hasDuty && <span>{title}</span>}
            </div>

        </div>
    )
}
export default AthleteCard