import axios from "../index"

const sendAppeal = data => {
    return axios.post('appeals', data)
}

const getAppealsTypes = query => {
    return axios.get(`appeals-type${query}`)
}


export default {sendAppeal,getAppealsTypes}