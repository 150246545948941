import React,{useState, useEffect} from 'react'
import styles from "./index.module.scss";
import img1 from "../../assets/images/canoe/3.jpg";
import img2 from "../../assets/images/canoe/4.jpg";
import ImageInfo from "../../components/core/imageInfo";
// import text from "./text.json";
import InnerPageHeader from "../../components/pages/innerPageHeader";
import ContactItem from "../../components/pages/contactItem";
import PageTitle from "../../components/core/pageTitle";
// import HomeService from "../../api/home";
import FederationService from "../../api/federation"
import Loading from "../../components/pages/loading";
import { MainContext, useContext } from "../../context";

function Federation() {
    const [load, SetLoad] = useState(false);
    const [data, setData] = useState([]);
    const { selectedLang } = useContext(MainContext);
    const linkBuilder = (url) => {
      return process.env.REACT_APP_STORAGE + url;
    };
    useEffect(async () => {
      const query = `?lang=${selectedLang}`;
      const {data} =await FederationService.getFederationData(query)
      setData(data);
      SetLoad(true);
    }, [selectedLang]);
    return load ? (
        <section className={styles.container}>
          <InnerPageHeader />
          <div className={styles.text}>
            <PageTitle>{data.title}</PageTitle>
          </div>
    
          <div className={styles.federation}>
            <ImageInfo
              photo1={linkBuilder(data.image_1)}
              photo2={linkBuilder(data.image_2)}
              title={data.title}
              header={data.title}
              text={data.description}
            />
          </div>
          <p  dangerouslySetInnerHTML={{__html:data.description}}></p>
          <figure>
            <img src={linkBuilder(data.image_1)} />
            <img src={linkBuilder(data.image_2)} />
          </figure>
          <ContactItem bg={false} />
        </section>
      ) : (
        <Loading />
      );
}

export default Federation