import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import Service from '../../api/training'
import Internal from '../../components/pages/Internal'
import Loading from '../../components/pages/loading'
import { MainContext, useContext } from "../../context";

const TrainingInternal = () => {
    const [load,SetLoad] = useState(false)
    const {id} = useParams()
    const [data,setData] = useState({})
    const [photos,setPhotos] = useState([])
    const {selectedLang} = useContext(MainContext)
    useEffect(async () => {
        const query = `?lang=${selectedLang}`
        const {data} = await Service.getDataById(id, query)
        await setData(data)
        await setPhotos(data.training_slider)
        SetLoad(true)
    },[id,data,photos, selectedLang])
    return (
        load?
          <Internal created={false} photos={photos} data={data}/>
        : <Loading/>
    )
}

export default TrainingInternal