import styles from './index.module.scss'
import ContactIcon from '../../components/core/contactIcon'
const Address = ({data}) => {
   return (
      <div className={styles['address-item']}>

         <ContactIcon>{<data.icon />}</ContactIcon>
         
         <div>
            <h6>{data.title}</h6>
            {data.text.map((item,i)=>(
               <p key={i}>{item}</p>
            ))}
         </div>

      </div>
   )
}

export default Address