import styles from "./index.module.scss";
import data_ from "./data.json";
import Title from "../../core/title";
import { LeftTimeLineArrowIcon, RightTimeLineArrowIcon } from "../../../icons";
import LineItem from "./LineItem";
import { useState, useLayoutEffect, useEffect } from "react";
import { MainContext, useContext } from "../../../context";

const AchievementsItem = ({ data }) => {
  const [current, setCurrent] = useState(0);
  const [current2, setCurrent2] = useState(0);
  const [disabledLeft, setDisabledLeft] = useState(true);
  const [disabledRight, setDisabledRight] = useState(false);
  const [margin, setMargin] = useState(280);
  const [limit, setLimit] = useState(3);
  const [max, setMax] = useState(0);
  const [backendData, setBackendData] = useState([]);
  const [len, setLen] = useState(null);
  const [clicked, setClicked] = useState(false);
  const Data = [...backendData, { year: "" }];
  const [load, SetLoad] = useState(false);
  const { selectedLang } = useContext(MainContext);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 1200 && window.innerWidth > 768) {
        setMargin(184);
        setLimit(0);
        setMax(Data.length - 4);
        return;
      } else if (window.innerWidth <= 768) {
        setMargin(184);
        setLimit(0);
        setMax(Data.length - 2);
        return;
      }
      setMargin(280);
      setLimit(3);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
  }, [Data.length]);
  const LeftClick = () => {
    setDisabledRight(false);
    let x = current - 1;
    setCurrent(x);
    if (x < max) {
      setCurrent2(x);
    }
    if (current === 1) {
      setDisabledLeft(true);
    }
  };
  const RightClick = () => {
    setDisabledLeft(false);
    let x = current + 1;
    setCurrent(x);
    if (x < max) {
      setCurrent2(x);
    }
    if (current === Data.length - 3) {
      setDisabledRight(true);
    }
  };
  const handleCircles = (i) => {
    setCurrent(i);
    if (i < max) {
      if (i === current - 1) {
        setCurrent2(i - 1);
      } else {
        setCurrent2(i);
      }
    }
    if (i === 0) {
      setDisabledLeft(true);
    } else {
      setDisabledLeft(false);
    }

    if (i === Data.length - 2) {
      setDisabledRight(true);
    } else {
      setDisabledRight(false);
    }
  };
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  useEffect(() => {
    if (Data.length === 1) {
      setLen(3);
    } else {
      setLen(Data.length);
    }
    const interval = setInterval(() => {
      if (!clicked) {
        let cur = current;
        if (cur < len - 2) {
          handleCircles(cur + 1);
        } else {
          handleCircles(0);
        }
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [current, len, clicked]);

  useEffect(async () => {
    setBackendData(data);
  }, [data]);
  return (
    <section className={`dark2 ${styles.achievements}`}>
      <div className={styles.container}>
        <div className={styles["content-header"]}>
          <Title>
            <h1>{data_[selectedLang].title}</h1>
          </Title>
          {/* <p>{data.description}</p> */}
        </div>

        <div className={styles["timeline-container"]}>
          <div
            style={
              disabledLeft
                ? {
                    pointerEvents: "none",
                    cursor: "not-allowed",
                    opacity: "0.5",
                  }
                : null
            }
            onClick={() => LeftClick()}
          >
            <LeftTimeLineArrowIcon />
          </div>
          <div className={styles.timeline}>
            <div
              style={
                current > limit
                  ? {
                      marginRight: `${
                        margin === 280
                          ? (2 * (current - 3) + 1) * margin
                          : margin * (2 * current2 + 1)
                      }px`,
                      transition: "all 700ms",
                    }
                  : { marginRight: `${margin}px` }
              }
            >
              {Data.map((item, i) => {
                if (i < current) {
                  return (
                    <LineItem
                      click={() => {
                        handleCircles(i);
                        setClicked(true);
                      }}
                      year={item.year}
                      className={styles["line-blue"]}
                    />
                  );
                } else if (i === current) {
                  return (
                    <LineItem
                      click={() => {
                        handleCircles(i);
                        setClicked(true);
                      }}
                      year={item.year}
                      className={styles["line-active"]}
                    />
                  );
                } else {
                  return (
                    <LineItem
                      click={() => {
                        handleCircles(i);
                        setClicked(true);
                      }}
                      year={item.year}
                      className={styles["line-grey"]}
                    />
                  );
                }
              })}
            </div>
          </div>
          <div
            style={
              disabledRight
                ? {
                    pointerEvents: "none",
                    cursor: "not-allowed",
                    opacity: "0.5",
                  }
                : null
            }
            onClick={() => RightClick()}
          >
            <RightTimeLineArrowIcon />
          </div>
        </div>

        <div className={styles.achievement}>
          {Data[current].image ? (
            <img
              onClick={() => setClicked(true)}
              src={linkBuilder(Data[current].image)}
              alt="Avarçəkmə"
            />
          ) : null}
          {Data[current].description ? (
            <p onClick={() => setClicked(true)}>{Data[current].description}</p>
          ) : null}
        </div>
      </div>
    </section>
  );
};

export default AchievementsItem;
