import styles from './index.module.scss'
import text from './text.json'
import PageTitle from "../../components/core/pageTitle"
import InnerPageHeader from "../../components/pages/innerPageHeader"
import {useParams,Link} from "react-router-dom";
import {useEffect, useState} from 'react'
import Service from '../../api/search'
import ContactItem from '../../components/pages/contactItem'
import Loading from '../../components/pages/loading'
import { MainContext, useContext } from '../../context';

const Search = () => {
   const [load,SetLoad] = useState(false)
   const [data,setData] = useState([])
   const [count,setCount] = useState()
   const [search,setSearch] = useState()
   const [current,setCurrent] = useState(1)
   const {selectedLang} = useContext(MainContext)
   const {searched} = useParams()
   useEffect(async () => {
      const {data} = await Service.getDataById(searched)
      setData(data?.axtarilanData.data)
      setCount(data?.say)
      setSearch(searched)
      SetLoad(true)
   },[])
   const generateLink = (type,id) => {
      switch(type) {
         case "elanlar":
            return "/announcement"
         case "atlet":
            return "/our-athletes"
         case "struktur":
            return "/lead"
         case "milliKomanda":
           return "/national-teams"
         case "tedbirler":
           return "/all-events"
         case "telim_bazalari":
           return `/training/${id}`
         case "xeberler":
           return `/news/${id}`
         case "avarcekmeNovleri":
            return `/type/${id}`
         case "nailiyyetler":
           return "/achievements"
         default:
            return "/"
      } 
   }
   return (
      load?
      <section className={styles.container}>
         <InnerPageHeader/>
         <PageTitle>{text[selectedLang].title}</PageTitle>

         <div className={styles.content}>
              {selectedLang == 'az' ? <h1>“{search}” sözü üzrə {count} nəticə tapıldı</h1> : selectedLang == 'en' ? <h1>{count} results found for “{search}”</h1> :  <h1>{count} результатов найдено для “{search}”</h1>}
              <ul>
                  {data.map(item=>
                  <Link to={generateLink(item?.type,item?.id)}>
                     <li>
                        <h2>{item.title}</h2>
                        <div>
                        <p
                        dangerouslySetInnerHTML={{
                           __html: item.description
                         }} /><div>...</div>
                        </div>
                     </li>
                  </Link>
                  )}
              </ul>
         </div>
     
         <ContactItem bg={false} />

      </section> : <Loading/>
   )
}

export default Search