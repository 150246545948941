import InnerPageHeader from "../../components/pages/innerPageHeader"
import text from './text.json'
import ContactItem from '../../components/pages/contactItem'
import Breadcrumb from '../../components/core/breadcrumb'
import PageTitle from '../../components/core/pageTitle'
import Table from '../../components/core/table'
import {useEffect, useState} from "react";
import TeamService from '../../api/teams'
import Loading from '../../components/pages/loading'
import {MainContext, useContext} from '../../context'
import styles from './index.module.scss'


const NationalTeams = () => {
   const [load,SetLoad] = useState(false)
   const [trainers_tabs,setTrainers_tabs] = useState([])
   const [girls_tabs,setGirls_tabs] = useState([])
   const [boys_tabs,setBoys_tabs] = useState([])
   const [academic_tabs,setAcademic_tabs] = useState([])
   const {selectedLang} = useContext(MainContext)

   useEffect(async () => {
      const query = `?lang=${selectedLang}`
      const {data} = await TeamService.getData(query)
      const linkBuilder = url => {
         return process.env.REACT_APP_STORAGE + url
      }
      const teams = (id, fullname, birth ,photo, description) => {
         return {
             id,
             fullname,
             birth,
             photo,
             description
         }
      }
      setTrainers_tabs(data.mesqci.data.map(item=>teams(item.id,item.name + "  " + item.surname,item.birth_year,linkBuilder(item.image),item.description))) 
      setGirls_tabs(data.qizlar.data.map(item=>teams(item.id,item.name + "  " + item.surname,item.birth_year,linkBuilder(item.image),item.description)))
      setBoys_tabs(data.oglanlar.data.map(item=>teams(item.id,item.name + "  " + item.surname,item.birth_year,linkBuilder(item.image),item.description)))
      setAcademic_tabs(data.akademikAvarcekme.data.map(item=>teams(item.id,item.name + "  " + item.surname,item.birth_year,linkBuilder(item.image),item.description)))
      SetLoad(true)
  },[selectedLang])

   const tabs = {
      "az" : [

         {
            "tab":"Məşqçilər",
            "data":trainers_tabs,
            "link" : "/"
         },
         {
            "tab":"Akademik avarçəkmə",
            "data":academic_tabs,
            "link" : "/"
         },
         {
            "tab":"Kanoe və kayak (oğlanlar)",
            "data":boys_tabs,
            "link" : "/"
         },
         {
            "tab":"Kanoe və kayak (qızlar)",
            "data":girls_tabs,
            "link" : "/"
         }
      ],
      "en" : [
         {
            "tab":"Coaches",
            "data":trainers_tabs,
            "link" : "/"
         },
         {
            "tab":"Academic rowing",
            "data":academic_tabs,
            "link" : "/"
         },
         {
            "tab":"Canoe və kayaking (boys)",
            "data":boys_tabs,
            "link" : "/"
         },
         {
            "tab":"Canoe və kayaking (girls)",
            "data":girls_tabs,
            "link" : "/"
         }
      ],
      "ru" : [
         {
            "tab":"Тренеры",
            "data":trainers_tabs,
            "link" : "/"
         },
         {
            "tab":"Академическая гребля",
            "data":academic_tabs,
            "link" : "/"
         },
         {
            "tab":"Гребля на каноэ и байдарках (мальчики)",
            "data":boys_tabs,
            "link" : "/"
         },
         {
            "tab":"Гребля на каноэ и байдарках (девочки)",
            "data":girls_tabs,
            "link" : "/"
         }
      ]
   }
   return (
      load?

      <section className={styles.teams}>

      <InnerPageHeader/>
      
      <div className={`dark ${styles.text}`}>
         <Breadcrumb crumbs={text[selectedLang].breadcrumbs} />
         <PageTitle>{text[selectedLang].text}</PageTitle>
      </div>
  
      <div className={`${styles['tabs-container']}`}>
         <Table tabs={tabs[selectedLang]} component={"team"} />
      </div>

      <div className={"dark"}><ContactItem bg={false} /></div>
      </section> : <Loading/>
   )
}

export default NationalTeams