import styles from "./index.module.scss";
import InnerPageHeader from "../../components/pages/innerPageHeader";
import text from "./text.json";
import Breadcrumb from "../../components/core/breadcrumb";
import PageTitle from "../../components/core/pageTitle";
import AddressImg from "../../assets/images/address.svg";
import Address from "./Address";
import { PhonesIcon, AddressIcon, MailIcon } from "../../icons";
import Textarea from "../../components/core/textarea";
import Input from "../../components/core/input";
import { FormButton } from "../../components/core/button";
import ContactItem from "../../components/pages/contactItem";
import { useEffect, useState } from "react";
import AppealsService from "../../api/appeals";
import ContactService from "../../api/contact";
import Loading from "../../components/pages/loading";
import { MainContext, useContext } from "../../context";

const Contact = () => {
  const [load, SetLoad] = useState(false);
  const [types, setTypes] = useState([]);
  const [contactinfo, setContactinfo] = useState([]);
  const { selectedLang } = useContext(MainContext);

  const inputItem = (id, type, placeholder) => {
    return {
      id,
      type,
      placeholder,
    };
  };
  const inputs = [
    inputItem("fullname", "text", text[selectedLang].appeal_form_name),
    inputItem("email", "email", text[selectedLang].appeal_form_email),
  ];
  const sendAppeal = async (e) => {
    e.preventDefault();
    const formData = {
      fullname: document.querySelector("#fullname").value,
      email: document.querySelector("#email").value,
      text: document.querySelector("#text").value,
      appeals_id: document.querySelector("#type").value,
    };
    const data = await AppealsService.sendAppeal(formData);
    if (data) {
      alert("Müarciətiniz uğurla göndərildi");
    }
  };
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await AppealsService.getAppealsTypes(query);
    setTypes(data);
    const contactData = await ContactService.getContactInfo(query);
    const contactItem = (id, icon, text, title) => {
      return {
        id,
        icon,
        text,
        title,
      };
    };
    await setContactinfo([
      contactItem(
        1,
        AddressIcon,
        [contactData?.data?.address],
        text[selectedLang].address
      ),
      contactItem(
        2,
        PhonesIcon,
        [contactData?.data?.phone, contactData?.data?.mobile_number],
        text[selectedLang].call
      ),
      contactItem(
        3,
        MailIcon,
        [contactData?.data?.email],
        text[selectedLang].mail
      ),
    ]);
    SetLoad(true);
  }, [selectedLang]);
  return load ? (
    <section className={styles.contact}>
      <InnerPageHeader />

      <div className={styles.text}>
        <Breadcrumb crumbs={text[selectedLang].breadcrumbs} />
        <PageTitle>{text[selectedLang].text}</PageTitle>
      </div>
      <ContactItem button={false} title={false} />
      <div className={styles.reverse}>
        <div className={styles.address}>
          <div>
            {contactinfo.map((data) => (
              <Address key={data.id} data={data} />
            ))}
          </div>
          <figure>
            {/* <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1519.5417169240764!2d49.86419480995505!3d40.38484341222319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307d108a12cc93%3A0x34cb59116d39b02a!2zMTUgWXVzaWYgU8mZZsmZcm92IGvDvMOnyZlzaSwgQmFrxLEsIEF6ZXJiYWlqYW4!5e0!3m2!1sen!2s!4v1665392322756!5m2!1sen!2s"
              allowfullscreen="true"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
                        <iframe
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%C6%8Fh%C9%99d%20Yaqubov%20k%C3%BC%C3%A7%C9%99si%2015+(My%20Business%20Name)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              allowfullscreen="true"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
           
            
          </figure>
        </div>
        <form onSubmit={sendAppeal} className={styles.appeal}>
          <h3>{text[selectedLang].appeal_title}</h3>
          <div>
            {inputs.map((item) => (
              <Input
                key={item.id}
                id={item.id}
                type={item.type}
                placeholder={item.placeholder}
              />
            ))}
            <select id="type">
              <option disabled selected hidden>
                {text[selectedLang].appeal_form_select}
              </option>
              {types?.map((item, i) => (
                <option key={i} value={item?.id}>
                  {item?.name}
                </option>
              ))}
            </select>
          </div>
          <Textarea
            name="text"
            placeholder={text[selectedLang].appeal_form_text}
          ></Textarea>
          <FormButton type="submit">
            {text[selectedLang].appeal_form_button}
          </FormButton>
        </form>
      </div>
    </section>
  ) : (
    <Loading />
  );
};

export default Contact;
