import styles from './index.module.scss'

const VideoCol = ({videos}) => {
   return (
      <div className={styles.video}>
         <iframe 
         src={`https://www.youtube.com/embed/${videos[0].video_code}`}>
         </iframe>
         {videos[1]?
            <iframe 
            src={`https://www.youtube.com/embed/${videos[1].video_code}`}>
            </iframe>
            :null
         }

      </div>
   )
}

export default VideoCol