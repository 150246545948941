import styles from './index.module.scss'
import staticWords from './../../../../translation.json'
import {Link} from "react-router-dom";
import {routes} from "../../../../routes";
import {RightArrowIcon} from "../../../../icons";
import Helpers from "../../../../utils/helpers";
import {MainContext, useContext} from '../../../../context'

const NewsCard = ({news}) => {
    const {selectedLang} = useContext(MainContext)
    const linkBuilder = url => {
        return process.env.REACT_APP_STORAGE + url
    }
    return (
        <Link to={`${routes.news.path}/${news?.id}`} className={styles['news-card']}>
            <div className={styles.header}>
                <img src={linkBuilder(news?.cover_image)} alt={news?.title}/>
            </div>
            <div className={styles.body}>
                <strong>{Helpers.textLengthChecker(news?.title, 50)}</strong>
                <div className={styles.footer}>
                    <Link to={`${routes.news.path}/${news?.id}`}>
                        <span>{staticWords[selectedLang].more}</span>
                        <RightArrowIcon/>
                    </Link>
                    <span>{news?.created_at}</span>
                </div>
            </div>
        </Link>
    )
}

export default NewsCard