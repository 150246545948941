import styles from './index.module.scss'

const InfoText = ({info}) => {
    return (
        <div className={`dark ${styles.infoText}`}>
            <p dangerouslySetInnerHTML={{__html: info}}/>
        </div>
    )
}

export default InfoText