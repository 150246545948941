import styles from "./index.module.scss";
import text from "./text.json";
import InnerPageHeader from "../innerPageHeader";
import Memories from "./Memories";
import InfoText from "./InfoText";
import Footer from "./Footer";
import ContactItem from "../contactItem";
import PageTitle from "../../core/pageTitle";
import Photos from "../photos";
import { Helmet } from "react-helmet";
import { useEffect } from "react";

const Internal = ({ data, photos, created = true }) => {
  document.title = "Avarçəkmə | " + data?.title;
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  console.log();
  useEffect(() => {
    let cleanText = data?.description.replace(/<\/?[^>]+(>|$)/g, "");
    document.querySelector('meta[property="og:title"]').content = data?.title;
    document.querySelector('meta[property="og:image"]').content =
      "https://backend.akaf.az/" + photos[0].image;
    document.querySelector('meta[property="og:description"]').content =
      cleanText;
    document.querySelector('meta[property="og:url"]').content =
      "https://akaf.az/news/" + data?.id;

    document.querySelector('meta[property="twitter:url"]').content =
      "https://akaf.az/news/" + data?.id;
    document.querySelector('meta[property="twitter:title"]').content =
      data?.title;
    document.querySelector('meta[property="twitter:description"]').content =
      cleanText;
    document.querySelector('meta[property="twitter:image"]').content =
      "https://backend.akaf.az/" + photos[0].image;
  });

  return (
    <section className={styles["internal"]}>
      {/* <Helmet>
        <meta property="og:title" content={data?.title} />
        <meta
          property="og:image"
          content={"https://backend.akaf.az/" + photos[0].image}
        />
        <meta property="og:url" content={"https://akaf.az/news/" + data?.id} />
        <meta property="og:description" content={data?.description} />
        <meta property="article:published_time" content={data?.created_at} />

        <meta name="description" content={data?.description} />
        <meta name="author" content="akaf.az" />
        <meta property="og:type" content="news" />
        <meta
          property="og:title"
          content="Azərbaycan Kanoe və Avarçəkmə Federasiyası"
        />
      </Helmet> */}
      <InnerPageHeader />
      {photos ? <Photos video={data?.video_link} data={photos} /> : null}
      {data?.title ? (
        <div className={`dark ${styles["mobile-text"]}`}>
          <h1>{data?.title}</h1>
          {created ? <p>{data?.created_at}</p> : null}
        </div>
      ) : null}
      <div
        className={`dark2 ${styles["container"]}`}
        style={data.title ? null : { paddingTop: "200px" }}
      >
        {data?.title ? (
          <div className={`dark ${styles.text}`}>
            <h1>{data?.title}</h1>
            <p>{created ? data?.created_at : null}</p>
          </div>
        ) : null}
        <div className={`dark2 ${styles.content}`}>
          {data?.id && (
            <div
              className={`dark2 ${styles.memories}`}
              style={data.title ? null : { marginTop: "-150px" }}
            >
              {photos ? (
                <Memories video={data?.video_link} images={photos} />
              ) : (
                <img
                  className={`dark`}
                  src={linkBuilder(data?.cover_image)}
                  alt={data?.title}
                />
              )}
              {data.header ? <h1 className={`dark`}>{data.header}</h1> : null}
              <InfoText info={data?.description} />
              <div className={`dark2 ${styles.line}`}></div>
              <Footer
                text={text["footer-text"]}
                shareTitle={data?.title}
                date={created ? data?.created_at : null}
              />
            </div>
          )}
        </div>
      </div>
      <ContactItem bg={false} />
    </section>
  );
};

export default Internal;
