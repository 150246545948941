import {VideoPlay } from '../../../icons'
import styles from './index.module.scss'
import {useState} from 'react'

const VideoItem = ({poster,click,icon=false}) => {
   const [play,setPlay] = useState(false)

   return (
         <div 
         className={styles.video}
         onClick={click}
         >
           <img src={poster} alt="Avarçəkmə" />
           {icon?<span style={icon?{width:"40px",height:"40px"}:null}><VideoPlay style={icon?{width:"16px"}:null} /></span>:null}
         </div>
   )
}

export default VideoItem