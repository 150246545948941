import styles from "./index.module.scss";
import { useEffect, useLayoutEffect, useState } from "react";
import Slide from "./slide";

const Slides = ({ data }) => {
  const [slides, setSlides] = useState([]);
  const [len, setLen] = useState(6);
  const [margin, setMargin] = useState(40 / (len - 1));
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth <= 992) {
        setMargin(0);
        return;
      }
      setMargin(40 / (len - 1));
    }

    window.addEventListener("resize", updateSize);
    updateSize();
  }, []);
  useEffect(async () => {
    setLen(data?.length);
    setSlides(data);
  }, [data]);
  return (
    <section className={`dark2 ${styles["slides-section"]}`}>
      <div className={styles.slides}>
        {slides.map((item, i) => (
          <Slide
            margin={margin}
            n={len}
            key={i}
            img={item?.cover_image}
            title={item?.title}
            i={i}
            id={item?.id}
          />
        ))}
      </div>
    </section>
  );
};

export default Slides;
