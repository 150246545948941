import styles from "./index.module.scss";
import data_ from "./data.json";
import Title from "../../../components/core/title";
import BottomImg from "../../../assets/images/bottom-img.webp";
import { LinkButton } from "../../../components/core/button";
import { RightArrowIcon } from "../../../icons";
import { useEffect, useState } from "react";
import img1 from "../../../assets/images/athletes/1.webp";
import img2 from "../../../assets/images/athletes/2.webp";
import img3 from "../../../assets/images/athletes/3.webp";
import img4 from "../../../assets/images/athletes/4.webp";
import { MainContext, useContext } from "./../../../context";

const Athletes = ({ data }) => {
  const { selectedLang } = useContext(MainContext);
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  const [images, setImages] = useState([]);
  const images2 = [img1, img2, img3, img4];
  useEffect(async () => {
    setImages(data);
  }, [data]);
  return (
    <section className={`dark2 ${styles.athletes}`}>
      <div className={styles.left}>
        {/* {images.map(item => (
                    <div className={styles.img} key={item?.id}>
                       <img key={item?.id} src={linkBuilder(item?.image)} alt="Avarçəkmə" />
                    </div>
                )
                )} */}
        {images2.map((item, i) => (
          <div className={styles.img} key={i}>
            <img key={i} src={item} alt="Avarçəkmə" />
          </div>
        ))}
      </div>

      <div className={styles.right}>
        <div className={styles.top}>
          <div className={styles["top-inside"]}>
            <Title>
              <h4>{data_[selectedLang].title}</h4>
            </Title>
            <LinkButton
              to={"/our-athletes"}
              className={styles.button}
              rightTrapezoid={true}
            >
              <span>
                {data_[selectedLang].all_athletes}
                <RightArrowIcon />
              </span>
            </LinkButton>
          </div>
        </div>
        <img className={styles.bottom} src={BottomImg} alt="Avarçəkmə" />
      </div>
    </section>
  );
};

export default Athletes;
