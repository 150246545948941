import styles from './index.module.scss'
import {Link} from 'react-router-dom'

const TrainingCard = ({img,title,text,link}) => {
   const linkBuilder = url => {
      return process.env.REACT_APP_STORAGE + url
  }
   return (
      <Link className={styles.card} to={link}>
         <img src={linkBuilder(img)} alt={title} />
         <div>
            <h3>{title}</h3>
            <p>{text}</p>
         </div>
      </Link>
   )
}

export default TrainingCard