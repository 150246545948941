import styles from './index.module.scss';
import text from './text.json'
import InnerPageHeader from "../../components/pages/innerPageHeader";
import PageTitle from "../../components/core/pageTitle";
import Breadcrumb from "../../components/core/breadcrumb";
import {AthleteCard} from "../../components/core/cards";
import {Module2} from '../../components/core/module'
import {useState,useEffect} from 'react'
import ContactItem from '../../components/pages/contactItem'
import StructureService from '../../api/structure'
import Loading from '../../components/pages/loading'
import { MainContext, useContext } from '../../context';

const Lead = () => {
   const [load,SetLoad] = useState(false)
   const [active, setActive] = useState(false)
    const [leader, setLeader] = useState({})
    const [first, setFirst] = useState([])
    const [second, setSecond] = useState([])
    const [third, setThird] = useState([])
    const {selectedLang} = useContext(MainContext)

    const linkBuilder = url => {
        return process.env.REACT_APP_STORAGE + url
    }
    const generateLeaders = (arr) => {
        return (
            arr.map((item,i)=> (
                <AthleteCard
                    key={item.id}
                    fullname={item.name +' '+ item.surname}
                    title={item.duty}
                    hasDuty={true}
                    photo={linkBuilder(item?.image)}
                    click={() => {
                        setLeader(item);
                        setActive(true)
                    }}
                />
            ))
        )
    }
    useEffect( async () => {
        const query = `?lang=${selectedLang}`
        const {data} = await StructureService.getData(query)
        let x1=[],x2=[],x3=[];
        for (let i = 0; i < data.length; i++) {
            if(data[i].duty === "Prezident"){
                x1.push(data[i])
                setFirst(x1)
            }
            else if(data[i].duty === "Vitse-prezident"){
                x2.push(data[i])
                setSecond(x2)
            }
            else{
                x3.push(data[i])
                setThird(x3) 
            }
        }
      SetLoad(true)
    },[selectedLang, leader])
    document.querySelectorAll(".dark").forEach((item)=>{
        item.style.filter = active ? "brightness(70%)" : null;
    })
    return (
      load?
      <section className={styles.parent}>
        <Module2
            img={linkBuilder(leader.image)}
            text={leader?.excerpt}
            fullname={leader.name + ' ' + leader.surname}
            active={active}
            close={() => {
                setActive(false)
            }}
        />
        <div className={`dark ${styles.lead}`}>
            <InnerPageHeader/>
            <Breadcrumb crumbs={text[selectedLang].breadcrumbs}/>
            <PageTitle>{text[selectedLang].title}</PageTitle>
            <div className={styles.container}>
                    <div className={styles.content}>
                        {generateLeaders(first)}
                    </div>
                    <div className={styles.content}>
                        {generateLeaders(second)}
                    </div>
                    <div className={styles.content}>
                        {generateLeaders(third)}
                    </div>
            </div>
            <ContactItem bg={false}/>
        </div> 
      </section>
: <Loading/>
    )
}

export default Lead