import styles from "./index.module.scss";
import { useState, useEffect } from "react";
import { CloseIcon } from "../../../icons";
import { MainContext, useContext } from "../../../context";

const Iframe = ({ close, link, data }) => {
  const [video, setVideo] = useState([]);
  const { selectedLang } = useContext(MainContext);

  useEffect(async () => {
    setVideo(data.youtube_video);
  }, [selectedLang]);
  return (
    <div className={styles.iframe}>
      <iframe
        src={`https://www.youtube.com/embed/${link ? link : video}?controls=1`}
      ></iframe>
      <span onClick={close}>
        Bağla <CloseIcon />
      </span>
    </div>
  );
};
export default Iframe;
