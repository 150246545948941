import styles from "./index.module.scss";
import data from "./data.json";
import LogoBlue from "../../../assets/images/logo-blue.svg";
import LogoWhite from "../../../assets/images/logo-white.svg";
import LogoBlueEng from "../../../assets/images/logo-blue-eng.svg";
import { Link, NavLink, useLocation, Redirect } from "react-router-dom";
import { routes } from "../../../routes";
import { useEffect, useState, useRef } from "react";
import { LinkButton } from "../../core/button";
import {
  MenuIcon,
  RightArrowIcon,
  HeaderFB,
  HeaderInsta,
  HeaderTwitter,
  HeaderYT,
  SearchIcon,
  CallIcon,
  LangArrows,
  LiveStream,
  CloseIcon,
  HeaderTiktok,
} from "../../../icons";
import HeaderIcon from "../../core/headerIcon";
import Sidebar from "../../core/sidebar";
import ContactService from "../../../api/contact";
import { MainContext, useContext } from "../../../context";

const Navbar = ({ title, isTransparent }) => {
  const location = useLocation();
  const searchRef = useRef(null);
  const [search, setSearch] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [contact, setContact] = useState({});
  const [links, setLinks] = useState([]);
  const { selectedLang, setSelectedLang } = useContext(MainContext);

  const handleSearch = (e) => {
    if (e.key === "Enter" && search.trim() !== "") {
      return (window.location.href = `/search/${search}`);
    }
  };
  const Search = () => {
    setSearch(searchRef.current.value);
  };
  const generateMenu = () => {
    return (
      <ul className={styles.menu}>
        {data[selectedLang].links.map((item) => (
          <li key={item.id}>
            <NavLink
              className={location.pathname === item.path ? styles.active : ""}
              to={item.path}
            >
              {item.name}
            </NavLink>
            {item.dropdown ? (
              <ul className={styles.dropdown}>
                {item.dropdown.map((item2, i) => (
                  <li key={`main-${i}`}>
                    <NavLink to={item2.path}>{item2.name}</NavLink>
                    {item2.submenu ? (
                      <ul className={styles.submenu}>
                        {item2.submenu.map((item3, i) => (
                          <li key={i}>
                            <NavLink to={item3.path}>{item3.name}</NavLink>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </li>
                ))}
              </ul>
            ) : null}
          </li>
        ))}
      </ul>
    );
  };
  useEffect(async () => {
    window.scrollTo(0, 0);
    document.title = title;
    setOpen(false);
    if (!isTransparent) {
      document.addEventListener("scroll", (e) => {
        if (window.scrollY > 20) {
          setIsScrolled(true);
          return;
        }
        setIsScrolled(false);
      });
    }
  }, [title]);
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await ContactService.getContactInfo(query);
    setContact(data);
    setLinks([
      {
        icon: HeaderFB,
        link: data?.facebook_link,
      },
      {
        icon: HeaderInsta,
        link: data?.instagram_link,
      },
      {
        icon: HeaderTiktok,
        link: data?.linkedin_link,
      },
      {
        icon: HeaderTwitter,
        link: data?.twitter_link,
      },
      {
        icon: HeaderYT,
        link: data?.youtube_link,
      },
    ]);
  }, [selectedLang]);
  const handleLang = (lang) => {
    localStorage.setItem("lang", lang);
    setSelectedLang(lang);
    localStorage.setItem("lang", lang);
    setIsVisible(false);
  };
  return (
    <div
      className={`${styles.header_container} dark dark2 ${
        !isScrolled && !isTransparent && styles.transparent
      }`}
    >
      <div className={styles.line}></div>
      <div className={styles["header-contact"]}>
        <div className={styles["contact-container"]}>
          <div>
            {links.map((item, i) => (
              <HeaderIcon key={i} link={item.link}>
                <item.icon />
              </HeaderIcon>
            ))}
          </div>
          <div>
            <div>
              <a href="">
                <LiveStream />
                <p>{data[selectedLang].live}</p>
              </a>
            </div>
            <div>
              <label>
                <SearchIcon />
                <input
                  onKeyPress={(e) => handleSearch(e)}
                  onInput={() => Search()}
                  ref={searchRef}
                  value={search}
                  type="search"
                  placeholder={data[selectedLang].search}
                />
              </label>
            </div>
            <div>
              <a href={`tel:+994${contact?.mobile_number?.substring(1)}`}>
                <CallIcon />
                <p>{contact?.mobile_number}</p>
              </a>
            </div>
            <div
              onBlur={() =>
                setTimeout(function () {
                  setIsVisible(false);
                }, 200)
              }
            >
              <span onClick={() => setIsVisible(true)} tabindex="1">
                {selectedLang.toUpperCase()}
                <LangArrows />
              </span>
              <ul style={isVisible ? { display: "flex" } : { display: "none" }}>
                {data[selectedLang].languages.map((item, i) => (
                  <li
                    onClick={() => {
                      handleLang(item.lang);
                    }}
                    key={i}
                  >
                    {item.lang.toLocaleUpperCase()}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header className={styles.header}>
        <Link to={routes.home.path} className={styles.logo}>
          <img
            // src={!isTransparent && !isScrolled ? LogoWhite : LogoBlue}
            src={selectedLang == "en" ? LogoBlueEng : LogoBlue}
            alt={data[selectedLang].avar_cekme}
          />
        </Link>
        <nav className="navbar">{generateMenu()}</nav>
        <div className={styles["button-group"]}>
          <LinkButton to={"/contact"} className={styles.button}>
            <span>
              {data[selectedLang].button_text}
              <RightArrowIcon />
            </span>
          </LinkButton>
        </div>
        <div className={styles["menu-icon"]}>
          {open ? (
            <CloseIcon
              onClick={() => {
                setOpen(false);
              }}
            />
          ) : (
            <MenuIcon
              onClick={() => {
                setOpen(true);
              }}
            />
          )}
        </div>
        {open ? (
          <Sidebar
            data={data}
            click={() => {
              setOpen(false);
            }}
          />
        ) : null}
      </header>
    </div>
  );
};

export default Navbar;
