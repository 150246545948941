import styles from './index.module.scss'

const LinetItem = ({year,className,click}) => {
   return (
      <div className={`${styles["line-item"]} ${className}`}>
         <div></div>
         <span onClick={click}>
            <p>{year}</p>
         </span>
      </div>
   )
}

export default LinetItem