import styles from "./index.module.scss";
import text from "./text.json";
import staticWords from "./../../../translation.json";
import { PlayIcon } from "../../../icons";
import { LinkButton } from "../../../components/core/button";
import Title from "../../../components/core/title";
import { RightArrowIcon } from "../../../icons";
import { useState, useEffect } from "react";
import { MainContext, useContext } from "../../../context";

const School = ({ open, data_ }) => {
  const [data, setData] = useState([]);
  const { selectedLang } = useContext(MainContext);
  useEffect(async () => {
    setData(data_);
  }, [data_]);
  return (
    <section className={`dark2 ${styles.school}`}>
      <div className={styles.video} />

      <div className={styles.content}>
        <header>
          <Title>
            <h1>{data.title}</h1>
          </Title>
          <p>{data.excerpt}</p>
        </header>
        <span onClick={open} className={styles.icon}>
          <PlayIcon />
        </span>
      </div>

      <div className={styles.info}>
        <h6>{data.title}</h6>
        <p>{data.description}</p>
        <LinkButton
          to={"/contact"}
          click={open}
          rightTrapezoid={true}
          className={styles.button}
        >
          <span>
            {staticWords[selectedLang].join_us}
            <RightArrowIcon />
          </span>
        </LinkButton>
      </div>
    </section>
  );
};

export default School;
