import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./styles.css";
import {X,Share,Maximize,Download,CloseIcon} from '../../../icons'
import VideoItem from '../../core/video'
import { FreeMode,Keyboard, Pagination, Navigation } from "swiper";

const Zoom = ({images,video,poster,click}) => {
  const [swiper, setSwiper] = useState(null);
  const slideTo = (index) => {
    if(swiper){ 
      swiper.slideTo(index)
    }
  };
  const linkBuilder = url => {
      return process.env.REACT_APP_STORAGE + url
  }
  return (
    <div className="swiper-zoom">
      <span onClick={click}>Bağla <CloseIcon/></span>
      {/* <div className={"controls"}>
         <Download/>
         <Maximize/>
         <Share/>
         <X onClick={click} />
      </div> */}

      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        keyboard={{
          enabled: true,
        }}
        navigation={true}
        modules={[Keyboard, Navigation]}
        className="zoom"
        onSwiper={setSwiper}
      >
         {images.map((item,i) => 
            <SwiperSlide><img src={linkBuilder(item?.image)}/></SwiperSlide>
         )}
          {video?<SwiperSlide>
            <iframe 
            src={`https://www.youtube.com/embed/${video}`}>
            </iframe>
            </SwiperSlide>:null}
      </Swiper>

      <Swiper
        slidesPerView={6}
        freeMode={true}
        modules={[FreeMode]}
        spaceBetween={16}
        grabCursor={true}
        className="bottom-slide"
      >
         {images.map((item,i) => 
            <SwiperSlide onClick={()=>{slideTo(i)}}><img src={linkBuilder(item?.image)}/></SwiperSlide>
          
         )}
          {video?<SwiperSlide onClick={()=>{slideTo(images.length)}}><VideoItem icon={true} poster={poster} video={video} /></SwiperSlide>:null}
      </Swiper>

    </div>
  );
}

export default Zoom
