import React from "react";
import styles from "./index.module.scss";
import FormLeft from "./FormLeft";
import FormRight from "./FormRight";

const Appeal = ({ dataLeft, dataRight }) => {
  return (
    <section className={`dark2 ${styles["appeal-container"]}`}>
      <div>
        <FormLeft data={dataLeft} />
        <FormRight data={dataRight} />
      </div>
    </section>
  );
};

export default Appeal;
