import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import NewsService from '../../api/news'
import Internal from '../../components/pages/Internal'
import Loading from '../../components/pages/loading'
import { MainContext, useContext } from "../../context";

const NewsInternal = () => {
    const [load,SetLoad] = useState(false)
    const {id} = useParams()
    const [news,setNews] = useState({})
    const [photos,setPhotos] = useState([])
    const {selectedLang} = useContext(MainContext)
    useEffect(async () => {
        const query = `?lang=${selectedLang}`
        const {data} = await NewsService.getNewsDetailById(id, query)
        await setNews(data)
        await setPhotos(data.news_slider)
        await SetLoad(true)
    },[selectedLang])
    return (
        load?
          <Internal photos={photos} data={news}/> : <Loading/>
    )
}

export default NewsInternal