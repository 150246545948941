import styles from './index.module.scss'
import staticWords from './../../../../translation.json'
import {Link} from 'react-router-dom'
import {RightArrowIcon} from '../../../../icons'
import {MainContext, useContext} from '../../../../context'


const AnnouncementCard = ({data}) => {
   const {selectedLang} = useContext(MainContext)
   const linkBuilder = url => {
      return process.env.REACT_APP_STORAGE + url
   }
   return (
      <div className={styles.card}>
         <img src={linkBuilder(data?.cover_image)} alt={data?.title}/>
         <div>
            <p>{data.created_at}</p>
            <h2>{data.title}</h2>
            <p 
            dangerouslySetInnerHTML={{
               __html: data?.description
             }}
            ></p>
            <div>
               <Link to={`/announcement/${data.id}`} className={styles.button}>
                     {staticWords[selectedLang].more}
                     <RightArrowIcon/>
               </Link>
               <p>{data.created_at}</p>
            </div>
         </div>
      </div>
   )
}

export default AnnouncementCard