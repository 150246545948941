import {Link} from "react-router-dom";
import styles from './index.module.scss';
const Column = ({title,links}) => {
   return(
      <div className={styles['column-container']}>
         <p>{title}</p>
         <div>
            {  
               links.map((link,i)=>(
                  <Link key={`link-${i}`} to={link.to} >{link.text}</Link>
               ))
            }
         </div>

      </div>
   )
}
export default Column;