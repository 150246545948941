import styles from './index.module.scss';
import ImageInfo from '../../components/core/imageInfo';
import text from './text.json';
import InnerPageHeader from '../../components/pages/innerPageHeader';
import ContactItem from '../../components/pages/contactItem';
import Photos from '../../components/pages/photos';
import PageTitle from '../../components/core/pageTitle';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import HomeService from '../../api/home';
import Loading from '../../components/pages/loading';
import { MainContext, useContext } from '../../context';
const Canoe = () => {
  const [load, SetLoad] = useState(false);
  const [data, setData] = useState([]);
  const { selectedLang } = useContext(MainContext);
  let { id } = useParams();

  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    window.scrollTo(0, 0);
    let { data } = await HomeService.getSlideById(parseInt(id), query);
    await setData(data);
    SetLoad(true);
  }, [selectedLang, id]);
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  return load ? (
    <section className={styles.container}>
      <InnerPageHeader />
      <div className={styles.text}>
        <PageTitle>{data?.title}</PageTitle>
      </div>

      <ImageInfo
        photo1={linkBuilder(data?.cover_image)}
        photo2={linkBuilder(data?.image)}
        button={true}
        title={text[selectedLang].title}
        header={data?.title}
        text={data?.description}
        video={data?.video_code}
      />
      <p>{data?.description}</p>
      <Photos video={data?.video_code} data={[data.cover_image, data.image]} />
      <ContactItem bg={false} />
    </section>
  ) : (
    <Loading />
  );
};

export default Canoe;
