const DownArrowIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 4L11 18.1667"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M16 14.2271L11 19.2271L6 14.2271"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};
const RightArrowIcon = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8L20 8"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2727 2L20.2727 8L14.2727 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const LeftArrowIcon = () => {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.1494 11L4.53941 11"
        stroke="#3C4F65"
        strokeWidth="2.66"
        strokeLinecap="square"
      />
      <path
        d="M10.8271 3.02002L2.84715 11L10.8271 18.98"
        stroke="#3C4F65"
        strokeWidth="2.66"
        strokeLinecap="square"
      />
    </svg>
  );
};
const MenuIcon = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33333 8H16"
        stroke="#12263E"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M5.33333 15.6667H26.6667"
        stroke="#12263E"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M16 23.3333H26.6667"
        stroke="#12263E"
        strokeWidth="2"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const LeftSlideIcon = (props) => {
  return (
    <svg
      {...props}
      width="82"
      height="30"
      viewBox="0 0 82 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.41421 14L15.4142 0L16.8284 1.41421L4.24265 14L81.4142 14V16L3.41421 16L15.4142 28L14 29.4142L0 15.4142L1.41421 14Z"
        fill="#12263E"
      />
    </svg>
  );
};

const RightSlideIcon = (props) => {
  return (
    <svg
      {...props}
      width="82"
      height="30"
      viewBox="0 0 82 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.4139 14L66.4139 0L64.9997 1.41421L77.5855 14L0.41391 14V16L78.4139 16L66.4139 28L67.8281 29.4142L81.8281 15.4142L80.4139 14Z"
        fill="#12263E"
      />
    </svg>
  );
};

const PhonesIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6299 11.4L11.1099 13.9C8.6099 12.47 6.5399 10.4 5.1099 7.9L7.6099 5.38C7.8399 5.14 7.9399 4.81 7.8799 4.48L7.1299 0.8C7.0399 0.34 6.6299 0 6.1499 0H1.9999C1.4399 0 0.969896 0.47 0.999896 1.03C1.1699 3.92 2.0499 6.63 3.4299 9C5.0099 11.73 7.2799 13.99 9.9999 15.57C12.3699 16.94 15.0799 17.83 17.9699 18C18.5299 18.03 18.9999 17.56 18.9999 17V12.85C18.9999 12.37 18.6599 11.96 18.1999 11.87L14.5299 11.14C14.3701 11.1052 14.2041 11.1108 14.047 11.1562C13.8899 11.2015 13.7465 11.2853 13.6299 11.4Z"
        fill="#0082CA"
      />
    </svg>
  );
};

const AddressIcon = () => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.507 13.24C17.161 12.0119 17.5021 10.6414 17.5 9.25C17.5 4.5555 13.6945 0.75 9.00001 0.75C4.30551 0.75 0.500012 4.5555 0.500012 9.25C0.496572 11.2551 1.20536 13.1963 2.50001 14.7275L2.51001 14.74C2.51351 14.743 2.51651 14.747 2.51901 14.75H2.50001L7.54401 20.105C7.73099 20.3035 7.95659 20.4616 8.20693 20.5697C8.45728 20.6778 8.72708 20.7336 8.99976 20.7336C9.27245 20.7336 9.54225 20.6778 9.79259 20.5697C10.0429 20.4616 10.2685 20.3035 10.4555 20.105L15.5 14.75H15.481L15.489 14.7405L15.49 14.7395C15.526 14.6965 15.562 14.6535 15.5975 14.6095C15.9443 14.1833 16.2491 13.7244 16.5075 13.2395L16.507 13.24ZM9.00201 12.5C8.20636 12.5 7.4433 12.1839 6.88069 11.6213C6.31808 11.0587 6.00201 10.2956 6.00201 9.5C6.00201 8.70435 6.31808 7.94129 6.88069 7.37868C7.4433 6.81607 8.20636 6.5 9.00201 6.5C9.79766 6.5 10.5607 6.81607 11.1233 7.37868C11.6859 7.94129 12.002 8.70435 12.002 9.5C12.002 10.2956 11.6859 11.0587 11.1233 11.6213C10.5607 12.1839 9.79766 12.5 9.00201 12.5Z"
        fill="#0082CA"
      />
    </svg>
  );
};

const MailIcon = () => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8002 13.3998V2.5998C20.8002 1.6038 19.9962 0.799805 19.0002 0.799805H2.18818C1.19218 0.799805 0.388184 1.6038 0.388184 2.5998V13.3998C0.388184 14.3958 1.19218 15.1998 2.18818 15.1998H19.0002C19.9962 15.1998 20.8002 14.3958 20.8002 13.3998ZM19.2282 2.4678C19.6242 2.8638 19.4082 3.27181 19.1922 3.47581L14.3202 7.93981L19.0002 12.8118C19.1442 12.9798 19.2402 13.2438 19.0722 13.4238C18.9162 13.6158 18.5562 13.6038 18.4002 13.4838L13.1562 9.00781L10.5882 11.3478L8.03218 9.00781L2.78818 13.4838C2.63218 13.6038 2.27218 13.6158 2.11618 13.4238C1.94818 13.2438 2.04418 12.9798 2.18818 12.8118L6.86818 7.93981L1.99618 3.47581C1.78018 3.27181 1.56418 2.8638 1.96018 2.4678C2.35618 2.0718 2.76418 2.2638 3.10018 2.55181L10.5882 8.59981L18.0882 2.55181C18.4242 2.2638 18.8322 2.0718 19.2282 2.4678Z"
        fill="#0082CA"
      />
    </svg>
  );
};
const FbIcon = () => {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.69841 14.3069L2.67794 8.04762H0V5.36508H2.67794V3.57672C2.67794 1.16315 4.17001 0 6.3194 0C7.34897 0 8.23384 0.0767832 8.49171 0.111102V2.63342L7.001 2.6341C5.83205 2.6341 5.60571 3.19052 5.60571 4.00704V5.36508H8.92648L8.03383 8.04762H5.60571V14.3069H2.69841Z"
        fill="#12263E"
      />
    </svg>
  );
};
const InstaIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 2H5.5C3.567 2 2 3.56737 2 5.50082V12.5025C2 14.4359 3.567 16.0033 5.5 16.0033H12.5C14.433 16.0033 16 14.4359 16 12.5025V5.50082C16 3.56737 14.433 2 12.5 2Z"
        stroke="#12263E"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7999 8.56172C11.8863 9.14443 11.7868 9.73955 11.5156 10.2624C11.2443 10.7853 10.8151 11.2093 10.2891 11.4742C9.76302 11.739 9.16686 11.8312 8.58539 11.7376C8.00393 11.644 7.46677 11.3694 7.05033 10.9529C6.63388 10.5364 6.35935 9.99907 6.26579 9.41748C6.17222 8.83588 6.26439 8.23958 6.52917 7.71339C6.79395 7.1872 7.21787 6.75792 7.74063 6.48661C8.26339 6.21529 8.85837 6.11576 9.44094 6.20217C10.0352 6.29031 10.5853 6.56728 11.0101 6.99217C11.4349 7.41706 11.7118 7.96734 11.7999 8.56172Z"
        stroke="#12263E"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="12.8491"
        cy="5.14986"
        rx="1.05025"
        ry="1.05025"
        fill="#12263E"
      />
    </svg>
  );
};
const TwitterIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="16px" height="16px"><path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"/></svg>
  );
};
const TiktokIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="2 3 40 40 "
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.0766847,15.8542954 C36.0693906,15.7935177 34.2504839,14.8341149 32.8791434,13.5466056 C32.1316475,12.8317108 31.540171,11.9694126 31.1415066,11.0151329 C30.7426093,10.0603874 30.5453728,9.03391952 30.5619062,8 L24.9731521,8 L24.9731521,28.8295196 C24.9731521,32.3434487 22.8773693,34.4182737 20.2765028,34.4182737 C19.6505623,34.4320127 19.0283477,34.3209362 18.4461858,34.0908659 C17.8640239,33.8612612 17.3337909,33.5175528 16.8862248,33.0797671 C16.4386588,32.6422142 16.0833071,32.1196657 15.8404292,31.5426268 C15.5977841,30.9658208 15.4727358,30.3459348 15.4727358,29.7202272 C15.4727358,29.0940539 15.5977841,28.4746337 15.8404292,27.8978277 C16.0833071,27.3207888 16.4386588,26.7980074 16.8862248,26.3604545 C17.3337909,25.9229017 17.8640239,25.5791933 18.4461858,25.3491229 C19.0283477,25.1192854 19.6505623,25.0084418 20.2765028,25.0219479 C20.7939283,25.0263724 21.3069293,25.1167239 21.794781,25.2902081 L21.794781,19.5985278 C21.2957518,19.4900128 20.7869423,19.436221 20.2765028,19.4380839 C18.2431278,19.4392483 16.2560928,20.0426009 14.5659604,21.1729264 C12.875828,22.303019 11.5587449,23.9090873 10.7814424,25.7878401 C10.003907,27.666593 9.80084889,29.7339663 10.1981162,31.7275214 C10.5953834,33.7217752 11.5748126,35.5530237 13.0129853,36.9904978 C14.4509252,38.4277391 16.2828722,39.4064696 18.277126,39.8028054 C20.2711469,40.1991413 22.3382874,39.9951517 24.2163416,39.2169177 C26.0948616,38.4384508 27.7002312,37.1209021 28.8296253,35.4300711 C29.9592522,33.7397058 30.5619062,31.7522051 30.5619062,29.7188301 L30.5619062,18.8324027 C32.7275484,20.3418321 35.3149087,21.0404263 38.0766847,21.0867664 L38.0766847,15.8542954 Z"
        id="Fill-1"
        fill="#444444"
      ></path>
    </svg>
  );
};

const RightTimeLineArrowIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 12H19"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L19 12L12 19"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const LeftTimeLineArrowIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12H5"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 19L5 12L12 5"
        stroke="#C4C4C4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PlayIcon = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.6523 13.9998L0.174079 27.555L0.17408 0.44465L23.6523 13.9998Z"
        fill="white"
      />
    </svg>
  );
};
const NewsFB = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#415993" />
      <path
        d="M14.1727 23.8957V16.6872H12.2666V14.0918H14.1727V11.875C14.1727 10.133 15.2986 8.53333 17.893 8.53333C18.9434 8.53333 19.7201 8.63403 19.7201 8.63403L19.6589 11.0577C19.6589 11.0577 18.8668 11.05 18.0023 11.05C17.0668 11.05 16.9169 11.4811 16.9169 12.1967V14.0918H19.7333L19.6107 16.6872H16.9169V23.8957H14.1727Z"
        fill="white"
      />
    </svg>
  );
};
const NewsWP = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#65CF72" />
      <g clip-path="url(#clip0_522_2672)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M20.0613 17.7865C19.8378 17.6748 18.742 17.1363 18.538 17.0613C18.334 16.987 18.1855 16.9502 18.0363 17.1737C17.8877 17.3958 17.461 17.8983 17.3312 18.0468C17.2007 18.196 17.071 18.214 16.8482 18.103C16.6255 17.9905 15.907 17.7558 15.0557 16.9968C14.3935 16.4058 13.9458 15.676 13.816 15.4525C13.6863 15.2298 13.8018 15.109 13.9135 14.998C14.014 14.8983 14.1362 14.7378 14.248 14.608C14.3597 14.4775 14.3965 14.3845 14.4708 14.2353C14.5458 14.0868 14.5082 13.957 14.452 13.8453C14.3965 13.7335 13.951 12.6363 13.765 12.19C13.5843 11.7558 13.4005 11.815 13.264 11.8075C13.1335 11.8015 12.985 11.8 12.8365 11.8C12.688 11.8 12.4465 11.8555 12.2425 12.079C12.0377 12.3018 11.4625 12.841 11.4625 13.9383C11.4625 15.0348 12.2605 16.0945 12.3723 16.2437C12.484 16.3923 13.9435 18.6438 16.1793 19.609C16.7118 19.8385 17.1265 19.9757 17.4497 20.0777C17.9837 20.248 18.4698 20.224 18.8538 20.1663C19.2812 20.1025 20.1723 19.627 20.3583 19.1065C20.5435 18.586 20.5435 18.1398 20.488 18.0468C20.4325 17.9538 20.284 17.8983 20.0605 17.7865H20.0613ZM15.9948 23.3387H15.9918C14.6638 23.339 13.3603 22.982 12.2177 22.3052L11.9478 22.1448L9.14125 22.8812L9.8905 20.1453L9.71425 19.8647C8.97185 18.683 8.57897 17.3153 8.581 15.9198C8.5825 11.8323 11.908 8.50675 15.9977 8.50675C17.9777 8.50675 19.8393 9.27925 21.2388 10.6803C21.929 11.3677 22.4762 12.1852 22.8485 13.0854C23.2208 13.9857 23.4109 14.9508 23.4078 15.925C23.4062 20.0125 20.0807 23.3387 15.9948 23.3387ZM22.3038 9.616C21.4774 8.78419 20.4942 8.12465 19.4111 7.6756C18.328 7.22655 17.1665 6.99691 15.994 7C11.0785 7 7.0765 11.0013 7.075 15.919C7.07425 17.491 7.4845 19.0255 8.26525 20.3777L7 25L11.728 23.7595C13.0359 24.4721 14.5016 24.8455 15.991 24.8455H15.9948C20.9102 24.8455 24.9123 20.8443 24.9137 15.9258C24.9174 14.7537 24.6886 13.5926 24.2406 12.5095C23.7926 11.4265 23.1343 10.443 22.3038 9.616Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_522_2672">
          <rect
            width="18"
            height="18"
            fill="white"
            transform="translate(7 7)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const NewsTG = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#2EA5D8" />
      <path
        d="M22.1618 10.1099L9.55377 14.9718C8.69332 15.3174 8.6983 15.7974 9.3959 16.0114L12.6329 17.0212L20.1223 12.2958C20.4764 12.0804 20.8 12.1963 20.534 12.4324L14.4661 17.9086H14.4647L14.4661 17.9094L14.2428 21.2459C14.5699 21.2459 14.7143 21.0958 14.8978 20.9188L16.47 19.3899L19.7404 21.8055C20.3435 22.1376 20.7765 21.967 20.9266 21.2473L23.0734 11.1296C23.2931 10.2486 22.7371 9.84962 22.1618 10.1099Z"
        fill="white"
      />
    </svg>
  );
};
const NewsLI = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="16px" height="16px"><path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"/></svg>
  );
};
const NewsTwitter = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#4AA1EB" />
      <g clip-path="url(#clip0_522_2686)">
        <path
          d="M24.2798 10.9774C23.686 11.2405 23.0481 11.4183 22.3776 11.4987C23.0695 11.0847 23.5871 10.4331 23.8339 9.66543C23.1839 10.0516 22.4724 10.3233 21.7305 10.469C21.2315 9.93627 20.5707 9.58317 19.8505 9.46452C19.1303 9.34587 18.3911 9.46831 17.7477 9.81281C17.1042 10.1573 16.5925 10.7046 16.292 11.3698C15.9915 12.0349 15.9189 12.7806 16.0857 13.4912C14.7685 13.4251 13.4799 13.0827 12.3036 12.4863C11.1272 11.89 10.0895 11.0529 9.25757 10.0295C8.97312 10.5202 8.80957 11.0891 8.80957 11.6949C8.80925 12.2404 8.94356 12.7774 9.20059 13.2585C9.45762 13.7395 9.82942 14.1497 10.283 14.4526C9.75696 14.4359 9.24254 14.2938 8.78255 14.0381V14.0807C8.78249 14.8457 9.0471 15.5871 9.53148 16.1792C10.0158 16.7713 10.6902 17.1776 11.44 17.3291C10.952 17.4611 10.4404 17.4806 9.94379 17.386C10.1553 18.0442 10.5674 18.6198 11.1224 19.0321C11.6773 19.4445 12.3473 19.673 13.0385 19.6857C11.8651 20.6069 10.4159 21.1066 8.92406 21.1044C8.65979 21.1044 8.39576 21.089 8.1333 21.0581C9.64759 22.0318 11.4103 22.5485 13.2106 22.5465C19.3049 22.5465 22.6364 17.499 22.6364 13.1214C22.6364 12.9792 22.6329 12.8356 22.6265 12.6933C23.2745 12.2247 23.8339 11.6444 24.2784 10.9796L24.2798 10.9774Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_522_2686">
          <rect
            width="17.0667"
            height="17.0667"
            fill="white"
            transform="translate(7.4668 7.46667)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
const BreadcrumbArrow = () => {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 7L4.5 4L1.5 1"
        stroke="white"
        strokeWidth="1.4"
        strokeLinecap="square"
      />
    </svg>
  );
};

const HeaderFB = () => {
  return (
    <svg
      width="9"
      height="16"
      viewBox="0 0 9 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25527 0.802688L6.43457 0.799988C4.38797 0.799988 3.06677 2.18149 3.06677 4.32079V5.94349H1.23617C1.19816 5.94384 1.16059 5.95169 1.12561 5.96659C1.09063 5.98149 1.05894 6.00315 1.03236 6.03033C1.00577 6.0575 0.984805 6.08965 0.970668 6.12495C0.956531 6.16024 0.949498 6.19797 0.949974 6.23599V8.58859C0.949974 8.74969 1.07777 8.88019 1.23617 8.88019H3.06677V15.6653C3.06677 15.8264 3.19547 15.9569 3.35297 15.9569H5.74157C5.77951 15.9565 5.81701 15.9487 5.85193 15.9339C5.88684 15.919 5.91849 15.8974 5.94507 15.8704C5.97165 15.8433 5.99263 15.8112 6.00682 15.7761C6.02101 15.7409 6.02813 15.7032 6.02777 15.6653V8.87929H8.16887C8.24534 8.87857 8.3184 8.84757 8.37205 8.79308C8.4257 8.73859 8.45555 8.66505 8.45507 8.58859V6.23509C8.45536 6.15823 8.42565 6.08429 8.37227 6.02899C8.3458 6.00186 8.31417 5.98032 8.27923 5.96564C8.2443 5.95095 8.20677 5.94342 8.16887 5.94349H6.02777V4.56739C6.02777 3.90589 6.18257 3.57019 7.02857 3.57019H8.25527C8.33174 3.56924 8.40471 3.53801 8.45819 3.48335C8.51167 3.42869 8.54129 3.35506 8.54057 3.27859V1.09429C8.54129 1.01782 8.51167 0.944186 8.45819 0.889525C8.40471 0.834865 8.33174 0.803637 8.25527 0.802688Z"
        fill="#444444"
      />
    </svg>
  );
};

const HeaderInsta = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.95241 5.30157C6.73019 5.30157 4.88892 7.1111 4.88892 9.36507C4.88892 11.619 6.69844 13.4286 8.95241 13.4286C11.2064 13.4286 13.0159 11.5873 13.0159 9.36507C13.0159 7.14284 11.1746 5.30157 8.95241 5.30157ZM8.95241 11.9682C7.52384 11.9682 6.34923 10.7936 6.34923 9.36507C6.34923 7.9365 7.52384 6.76189 8.95241 6.76189C10.381 6.76189 11.5556 7.9365 11.5556 9.36507C11.5556 10.7936 10.381 11.9682 8.95241 11.9682Z"
        fill="#444444"
      />
      <path
        d="M13.1745 6.12698C13.683 6.12698 14.0952 5.71479 14.0952 5.20634C14.0952 4.69789 13.683 4.28571 13.1745 4.28571C12.6661 4.28571 12.2539 4.69789 12.2539 5.20634C12.2539 5.71479 12.6661 6.12698 13.1745 6.12698Z"
        fill="#444444"
      />
      <path
        d="M15.5555 2.82539C14.7301 1.96825 13.5555 1.5238 12.2222 1.5238H5.68252C2.92062 1.5238 1.07935 3.36507 1.07935 6.12698V12.6349C1.07935 14 1.52379 15.1746 2.41268 16.0317C3.26982 16.8571 4.41268 17.2698 5.71427 17.2698H12.1905C13.5555 17.2698 14.6984 16.8254 15.5238 16.0317C16.3809 15.2063 16.8254 14.0317 16.8254 12.6667V6.12698C16.8254 4.79364 16.3809 3.65079 15.5555 2.82539ZM15.4286 12.6667C15.4286 13.6508 15.0793 14.4444 14.5079 14.9841C13.9365 15.5238 13.1428 15.8095 12.1905 15.8095H5.71427C4.76189 15.8095 3.96823 15.5238 3.39681 14.9841C2.82538 14.4127 2.53966 13.619 2.53966 12.6349V6.12698C2.53966 5.1746 2.82538 4.38095 3.39681 3.80952C3.93649 3.26984 4.76189 2.98412 5.71427 2.98412H12.2539C13.2063 2.98412 14 3.26984 14.5714 3.84126C15.1111 4.41269 15.4286 5.20634 15.4286 6.12698V12.6667Z"
        fill="#444444"
      />
    </svg>
  );
};

const HeaderTiktok = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="2 2 42 42"
      fill="#444444"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38.0766847,15.8542954 C36.0693906,15.7935177 34.2504839,14.8341149 32.8791434,13.5466056 C32.1316475,12.8317108 31.540171,11.9694126 31.1415066,11.0151329 C30.7426093,10.0603874 30.5453728,9.03391952 30.5619062,8 L24.9731521,8 L24.9731521,28.8295196 C24.9731521,32.3434487 22.8773693,34.4182737 20.2765028,34.4182737 C19.6505623,34.4320127 19.0283477,34.3209362 18.4461858,34.0908659 C17.8640239,33.8612612 17.3337909,33.5175528 16.8862248,33.0797671 C16.4386588,32.6422142 16.0833071,32.1196657 15.8404292,31.5426268 C15.5977841,30.9658208 15.4727358,30.3459348 15.4727358,29.7202272 C15.4727358,29.0940539 15.5977841,28.4746337 15.8404292,27.8978277 C16.0833071,27.3207888 16.4386588,26.7980074 16.8862248,26.3604545 C17.3337909,25.9229017 17.8640239,25.5791933 18.4461858,25.3491229 C19.0283477,25.1192854 19.6505623,25.0084418 20.2765028,25.0219479 C20.7939283,25.0263724 21.3069293,25.1167239 21.794781,25.2902081 L21.794781,19.5985278 C21.2957518,19.4900128 20.7869423,19.436221 20.2765028,19.4380839 C18.2431278,19.4392483 16.2560928,20.0426009 14.5659604,21.1729264 C12.875828,22.303019 11.5587449,23.9090873 10.7814424,25.7878401 C10.003907,27.666593 9.80084889,29.7339663 10.1981162,31.7275214 C10.5953834,33.7217752 11.5748126,35.5530237 13.0129853,36.9904978 C14.4509252,38.4277391 16.2828722,39.4064696 18.277126,39.8028054 C20.2711469,40.1991413 22.3382874,39.9951517 24.2163416,39.2169177 C26.0948616,38.4384508 27.7002312,37.1209021 28.8296253,35.4300711 C29.9592522,33.7397058 30.5619062,31.7522051 30.5619062,29.7188301 L30.5619062,18.8324027 C32.7275484,20.3418321 35.3149087,21.0404263 38.0766847,21.0867664 L38.0766847,15.8542954 Z"
        id="Fill-1"
        fill="#444444"
      ></path>
    </svg>
  );
};

const HeaderTwitter = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="16px" height="16px"><path d="M 6.9199219 6 L 21.136719 26.726562 L 6.2285156 44 L 9.40625 44 L 22.544922 28.777344 L 32.986328 44 L 43 44 L 28.123047 22.3125 L 42.203125 6 L 39.027344 6 L 26.716797 20.261719 L 16.933594 6 L 6.9199219 6 z"/></svg>
  );
};

const HeaderYT = () => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1092 1.74213C14.9289 0.958909 14.2883 0.381068 13.5171 0.29487C11.6909 0.0910069 9.8424 0.0899428 8.00247 0.091007C6.16222 0.0899428 4.31347 0.0910069 2.4872 0.29487C1.71645 0.381068 1.07628 0.958909 0.895978 1.74213C0.639363 2.85753 0.636475 4.07508 0.636475 5.22347C0.636475 6.37185 0.636475 7.58926 0.89309 8.70451C1.07309 9.48755 1.71341 10.0654 2.48463 10.1517C4.31073 10.3558 6.15933 10.3568 7.99958 10.3558C9.84013 10.3568 11.6883 10.3558 13.5142 10.1517C14.285 10.0656 14.9258 9.48773 15.1061 8.70451C15.3628 7.58911 15.3637 6.37171 15.3637 5.22347C15.3637 4.07508 15.3659 2.85753 15.1092 1.74213ZM6.09959 7.5093C6.09959 5.88021 6.09959 4.26816 6.09959 2.63892C7.66042 3.45331 9.2109 4.26223 10.7816 5.08163C9.21576 5.89374 7.66452 6.6978 6.09959 7.5093Z"
        fill="#444444"
      />
    </svg>
  );
};

const SearchIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33333 13.1667C10.2789 13.1667 12.6667 10.7789 12.6667 7.83333C12.6667 4.88781 10.2789 2.5 7.33333 2.5C4.38781 2.5 2 4.88781 2 7.83333C2 10.7789 4.38781 13.1667 7.33333 13.1667Z"
        stroke="#0082CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0001 14.5L11.1001 11.6"
        stroke="#0082CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CallIcon = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.76524 3.86236C12.3673 4.18966 14.4047 6.22713 14.732 8.82918C14.7811 9.2465 15.133 9.54925 15.5421 9.54925C15.5748 9.54925 15.6076 9.54925 15.6403 9.54107C16.0903 9.48379 16.4095 9.07466 16.3522 8.62462C15.9349 5.27795 13.3083 2.65134 9.96162 2.23403C9.51158 2.18493 9.10245 2.50405 9.05336 2.9541C8.99608 3.40414 9.3152 3.81327 9.76524 3.86236ZM10.0762 5.58888C9.6425 5.47433 9.19246 5.73617 9.07791 6.17803C8.96335 6.61989 9.22519 7.06175 9.66705 7.1763C10.5262 7.39723 11.1972 8.0682 11.4263 8.93555C11.5245 9.30377 11.8518 9.54925 12.22 9.54925C12.2854 9.54925 12.3591 9.54107 12.4246 9.5247C12.8582 9.41015 13.1201 8.9601 13.0137 8.52643C12.6291 7.07811 11.5081 5.9571 10.0762 5.58888H10.0762ZM14.9611 12.2168L12.8828 11.9795C12.3836 11.9222 11.8927 12.094 11.5408 12.4459L10.0353 13.9515C7.71959 12.7732 5.82123 10.883 4.64294 8.55917L6.15672 7.04539C6.50856 6.69354 6.6804 6.20258 6.62313 5.70344L6.38583 3.64143C6.28764 2.81499 5.59212 2.19312 4.7575 2.19312H3.34191C2.41728 2.19312 1.64812 2.96228 1.7054 3.88691C2.13908 10.8748 7.72778 16.4553 14.7075 16.889C15.6321 16.9463 16.4013 16.1771 16.4013 15.2525V13.8369C16.4095 13.0105 15.7876 12.315 14.9611 12.2168H14.9611Z"
        fill="#0082CA"
      />
    </svg>
  );
};
const LangArrows = () => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 1.75L5 5.25L8.5 1.75"
        stroke="#8A95A3"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const CloseIcon = (props) => {
  return (
    <svg
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.999 8L7.99902 24"
        stroke="#8A95A3"
        strokeWidth="3.5511"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M7.99902 8L23.999 24"
        stroke="#8A95A3"
        strokeWidth="3.5511"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const LiveStream = () => {
  return (
    <svg
      width="23"
      height="19"
      viewBox="0 0 23 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.75544 9.5007C2.76079 6.88778 3.67557 4.35821 5.34274 2.34626C5.53368 2.11654 5.62554 1.82037 5.59812 1.52291C5.57069 1.22546 5.42623 0.951079 5.1965 0.760138C4.96678 0.569197 4.67061 0.477335 4.37315 0.50476C4.0757 0.532185 3.80132 0.676651 3.61038 0.906377C1.61334 3.32521 0.520996 6.364 0.520996 9.5007C0.520996 12.6374 1.61334 15.6762 3.61038 18.095C3.71614 18.222 3.84859 18.3241 3.9983 18.3941C4.14801 18.4641 4.3113 18.5003 4.47656 18.5C4.7394 18.5005 4.99412 18.409 5.1965 18.2413C5.31041 18.1468 5.40457 18.0309 5.47358 17.9C5.5426 17.7691 5.58511 17.6259 5.5987 17.4785C5.61228 17.3312 5.59667 17.1826 5.55275 17.0413C5.50882 16.9001 5.43746 16.7688 5.34274 16.6551C3.67557 14.6432 2.76079 12.1136 2.75544 9.5007Z"
        fill="#FF4747"
      />
      <path
        d="M11.7545 11.7504C12.997 11.7504 14.0043 10.7432 14.0043 9.50062C14.0043 8.25807 12.997 7.25079 11.7545 7.25079C10.5119 7.25079 9.50464 8.25807 9.50464 9.50062C9.50464 10.7432 10.5119 11.7504 11.7545 11.7504Z"
        fill="#FF4747"
      />
      <path
        d="M17.4466 5.54103C17.3818 5.39759 17.2876 5.26936 17.1701 5.16462C17.0526 5.05988 16.9145 4.98096 16.7645 4.93296C16.6146 4.88497 16.4563 4.86897 16.2999 4.886C16.1434 4.90302 15.9922 4.9527 15.8561 5.03181C15.7201 5.11093 15.6021 5.21771 15.5099 5.34527C15.4177 5.47284 15.3533 5.61833 15.3208 5.77235C15.2883 5.92637 15.2886 6.08548 15.3215 6.23941C15.3544 6.39333 15.4192 6.53864 15.5118 6.66594C16.0054 7.52098 16.2653 8.4909 16.2653 9.47822C16.2653 10.4655 16.0054 11.4355 15.5118 12.2905C15.4342 12.4173 15.3826 12.5583 15.3598 12.7052C15.3371 12.8521 15.3437 13.0021 15.3792 13.1465C15.4148 13.2908 15.4786 13.4267 15.567 13.5462C15.6554 13.6658 15.7666 13.7666 15.8942 13.8429C16.0732 13.943 16.2742 13.9971 16.4792 14.0004C16.6733 14.0015 16.8645 13.9524 17.034 13.8577C17.2035 13.7631 17.3457 13.6262 17.4466 13.4604C18.1312 12.2611 18.4913 10.9041 18.4913 9.52321C18.4913 8.14232 18.1312 6.78528 17.4466 5.58602V5.54103Z"
        fill="#FF4747"
      />
      <path
        d="M7.61484 5.1585C7.488 5.08094 7.34705 5.02929 7.20012 5.00654C7.0532 4.98378 6.90323 4.99038 6.75887 5.02595C6.61452 5.06151 6.47865 5.12534 6.35911 5.21374C6.23957 5.30214 6.13875 5.41336 6.06246 5.54097C5.37788 6.74023 5.01782 8.09726 5.01782 9.47816C5.01782 10.8591 5.37788 12.2161 6.06246 13.4153C6.15774 13.5896 6.29743 13.7354 6.46735 13.8382C6.63727 13.9409 6.83135 13.9969 7.02988 14.0003C7.2349 13.9971 7.43591 13.943 7.61484 13.8428C7.74244 13.7665 7.85366 13.6657 7.94206 13.5462C8.03046 13.4266 8.09429 13.2908 8.12985 13.1464C8.16542 13.002 8.17202 12.8521 8.14927 12.7052C8.12652 12.5582 8.07487 12.4173 7.99731 12.2904C7.50365 11.4354 7.24376 10.4655 7.24376 9.47816C7.24376 8.49084 7.50365 7.52092 7.99731 6.66588C8.13992 6.41371 8.17975 6.11625 8.1085 5.83545C8.03725 5.55465 7.86042 5.31217 7.61484 5.1585Z"
        fill="#FF4747"
      />
      <path
        d="M19.9106 0.906377C19.816 0.792628 19.7 0.698615 19.5691 0.629706C19.4382 0.560796 19.2951 0.51834 19.1478 0.50476C18.8503 0.477335 18.5542 0.569197 18.3244 0.760138C18.0947 0.951079 17.9502 1.22546 17.9228 1.52291C17.8954 1.82037 17.9872 2.11654 18.1782 2.34626C19.8385 4.36082 20.7465 6.89012 20.7465 9.5007C20.7465 12.1113 19.8385 14.6406 18.1782 16.6551C18.0835 16.7688 18.0121 16.9001 17.9682 17.0413C17.9243 17.1826 17.9086 17.3312 17.9222 17.4785C17.9358 17.6259 17.9783 17.7691 18.0473 17.9C18.1164 18.0309 18.2105 18.1468 18.3244 18.2413C18.5268 18.409 18.7815 18.5005 19.0444 18.5C19.2096 18.5003 19.3729 18.4641 19.5226 18.3941C19.6723 18.3241 19.8048 18.222 19.9106 18.095C21.9076 15.6762 22.9999 12.6374 22.9999 9.5007C22.9999 6.364 21.9076 3.32521 19.9106 0.906377Z"
        fill="#FF4747"
      />
    </svg>
  );
};
const HeaderArrow = () => {
  return (
    <svg
      width="22"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8L19 8"
        stroke="#0082CA"
        strokeWidth="2"
        strokeLinecap="square"
      />
      <path
        d="M14.2727 2L20.2727 8L14.2727 14"
        stroke="#0082CA"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};
const MenuDown = () => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 1.5L7 6.5L12 1.5"
        stroke="#12263E"
        strokeWidth="2"
        strokeLinecap="square"
      />
    </svg>
  );
};
const VideoPlay = (props) => {
  return (
    <svg
      {...props}
      width="25"
      height="28"
      viewBox="0 0 25 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25 14L0.751288 28L0.751289 -1.05994e-06L25 14Z"
        fill="#0082CA"
      />
    </svg>
  );
};
const Download = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 10L12 15L17 10"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 15V3"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const Maximize = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V8M21 8V5C21 4.46957 20.7893 3.96086 20.4142 3.58579C20.0391 3.21071 19.5304 3 19 3H16M16 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V16M3 16V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H8"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const Share = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8C19.6569 8 21 6.65685 21 5C21 3.34315 19.6569 2 18 2C16.3431 2 15 3.34315 15 5C15 6.65685 16.3431 8 18 8Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 15C7.65685 15 9 13.6569 9 12C9 10.3431 7.65685 9 6 9C4.34315 9 3 10.3431 3 12C3 13.6569 4.34315 15 6 15Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18 22C19.6569 22 21 20.6569 21 19C21 17.3431 19.6569 16 18 16C16.3431 16 15 17.3431 15 19C15 20.6569 16.3431 22 18 22Z"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.58984 13.51L15.4198 17.49"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.4098 6.51001L8.58984 10.49"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
const X = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export {
  X,
  Share,
  Maximize,
  Download,
  VideoPlay,
  MenuDown,
  DownArrowIcon,
  RightArrowIcon,
  LeftArrowIcon,
  MenuIcon,
  LeftSlideIcon,
  RightSlideIcon,
  PhonesIcon,
  AddressIcon,
  MailIcon,
  FbIcon,
  InstaIcon,
  TwitterIcon,
  TiktokIcon,
  LeftTimeLineArrowIcon,
  RightTimeLineArrowIcon,
  PlayIcon,
  NewsFB,
  NewsWP,
  NewsTG,
  NewsLI,
  NewsTwitter,
  BreadcrumbArrow,
  HeaderFB,
  HeaderInsta,
  HeaderTiktok,
  HeaderTwitter,
  HeaderYT,
  SearchIcon,
  CallIcon,
  LangArrows,
  CloseIcon,
  LiveStream,
  HeaderArrow,
};
