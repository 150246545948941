import { useState, useEffect } from "react";
import styles from "./index.module.scss";
import img1 from "../../assets/images/canoe/01.webp";
import img2 from "../../assets/images/canoe/02.webp";
import ImageInfo from "../../components/core/imageInfo";
import text from "./text.json";
import InnerPageHeader from "../../components/pages/innerPageHeader";
import ContactItem from "../../components/pages/contactItem";
import PageTitle from "../../components/core/pageTitle";
import HomeService from "../../api/home";
import Loading from "../../components/pages/loading";
import { MainContext, useContext } from "../../context";

const History = () => {
  const [load, SetLoad] = useState(false);
  const [data, setData] = useState([]);
  const { selectedLang } = useContext(MainContext);
  const linkBuilder = (url) => {
    return process.env.REACT_APP_STORAGE + url;
  };
  useEffect(async () => {
    const query = `?lang=${selectedLang}`;
    const { data } = await HomeService.getSportData(3, query);
    setData(data);
    SetLoad(true);
  }, [selectedLang]);
  return load ? (
    <section className={styles.container}>
      <InnerPageHeader />
      <div className={styles.text}>
        <PageTitle>{data?.bottompart_title}</PageTitle>
      </div>

      <div>
        <ImageInfo
          photo1={img1}
          photo2={img2}
          title={text[selectedLang].title}
          header={data?.bottompart_title}
          text={data?.bottompart_description}
        />
      </div>
      <p>{data?.bottompart_description}</p>
      <figure>
        <img src={img1} />
        <img src={img2} />
      </figure>
      <ContactItem bg={false} />
    </section>
  ) : (
    <Loading />
  );
};
export default History;
